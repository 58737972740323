import { useContext, useState } from 'react';
import { SidebarContext } from '../../../contexts/zoom-context';
import * as Icons from '../../common/Icons';
import SpotlightCard from './SpotlightCard';
import SpotlightTabButton from './SpotlightTabButton';
import SpotlightFilterToggle from './SpotlightFilterToggle';
import SpotlightTopicToggle from './SpotlightTopicToggle';
import { isOdd, isEven } from '../../../util/util';

import './SpotlightPage.css';

const Tabs = Object.freeze({
    ALL: 0,
    SAVED: 1
});

const SortItems = Object.freeze({
    BEST_MATCH: 0,
    DISTANCE: 1
});

const TopicItems = Object.freeze({
    HOMELESSNESS: 0,
    HOUSING: 1,
    FOOD: 2
});

const DistanceFilterItems = [
    'Any',
    '5km',
    '10km',
    '25km',
    '50km',
    '100km'
];

const SpotlightPage = ({ className }) => {
    const { spotlightCards: cards, setSpotlightCards: setCards } = useContext(SidebarContext);
    const [currTab, setCurrTab] = useState(Tabs.ALL);
    const [singleCardToView, setSingleCardToView] = useState(undefined);
    const [sortItem, setSortItem] = useState(SortItems.BEST_MATCH);
    const [distanceFilterItem, setDistanceFilterItem] = useState(DistanceFilterItems[0]);
    const [topicItem, setTopicItem] = useState();

    const toggleSave = (id) => {
        if (singleCardToView && singleCardToView.id === id) {
            const newCard = { ...singleCardToView, saved: !singleCardToView.saved };
            setSingleCardToView(newCard);
        }
        const updatedCards = cards.map((card) => (card.id === id) ? { ...card, saved: !card.saved } : card);
        setCards(updatedCards);
    }

    const satisfiesFilters = (card) => {
        if (currTab === Tabs.SAVED) {
            return card.saved;
        }
        return true;
    }

    return (
        <div className={`${className} spotlight`}>
            <h1>Spotlight</h1>
            <div className='tab-btns'>
                <SpotlightTabButton
                    isActive={currTab === Tabs.ALL}
                    onClick={() => setCurrTab(Tabs.ALL)}
                    Icon={Icons.Spotlight}>
                    All Links
                </ SpotlightTabButton>
                <SpotlightTabButton
                    isActive={currTab === Tabs.SAVED}
                    onClick={() => setCurrTab(Tabs.SAVED)}
                    Icon={Icons.Bookmark}>
                    Saved Resources
                </SpotlightTabButton>
            </div>
            {singleCardToView ?
                <div onClick={() => setSingleCardToView(undefined)} className='back-btn'>
                    <Icons.CaretLeft />
                    Back to Resources
                </div>
                :
                <>
                    <div className='filter-row'>
                        <div className='filter-group'>
                            <div className='filter-group-label'>Sort Results by</div>
                            <div className='filter-btns'>
                                <SpotlightFilterToggle
                                    onClick={() => setSortItem(SortItems.BEST_MATCH)}
                                    isActive={sortItem === SortItems.BEST_MATCH}>
                                    Best Match
                                </SpotlightFilterToggle>
                                <SpotlightFilterToggle
                                    onClick={() => setSortItem(SortItems.DISTANCE)}
                                    isActive={sortItem === SortItems.DISTANCE}>
                                    Distance
                                </SpotlightFilterToggle>
                            </div>
                        </div>
                        <div className='filter-group'>
                            <div className='filter-group-label'>Filter by Distance</div>
                            <div className='filter-btns'>
                                {DistanceFilterItems.map((distance, index) =>
                                    <SpotlightFilterToggle
                                        key={index}
                                        onClick={() => setDistanceFilterItem(distance)}
                                        isActive={distanceFilterItem === distance}>
                                        {distance}
                                    </SpotlightFilterToggle>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='filter-row'>
                        <div className='filter-group'>
                            <div className='filter-group-label'>Resource Topics</div>
                            <div className='filter-btns'>
                                <SpotlightTopicToggle
                                    onClick={() => setTopicItem(TopicItems.HOMELESSNESS)}
                                    isActive={topicItem === TopicItems.HOMELESSNESS}
                                    Icon={Icons.Bed}>
                                    Homelessness
                                </SpotlightTopicToggle>
                                <SpotlightTopicToggle
                                    onClick={() => setTopicItem(TopicItems.HOUSING)}
                                    isActive={topicItem === TopicItems.HOUSING}
                                    Icon={Icons.House}>
                                    Housing
                                </SpotlightTopicToggle>
                                <SpotlightTopicToggle
                                    onClick={() => setTopicItem(TopicItems.FOOD)}
                                    isActive={topicItem === TopicItems.FOOD}
                                    Icon={Icons.BreadSlice}>
                                    Food
                                </SpotlightTopicToggle>
                            </div>
                        </div>
                    </div>
                </>}
            <div className='cards-container'>
                {singleCardToView ?
                    <div className='single-card-view'>
                        <SpotlightCard card={singleCardToView} toggleSave={toggleSave} isExpanded={true} />
                    </div>
                    :
                    <div className='grid-view'>
                        <div className='column'>
                            {cards.filter(card => satisfiesFilters(card)).filter((card, index) => isEven(index)).map((card, index) => {
                                return <SpotlightCard key={index} card={card} onClick={() => setSingleCardToView(card)} toggleSave={toggleSave} isExpanded={false} />
                            })}
                        </div>
                        <div className='column'>
                            {cards.filter(card => satisfiesFilters(card)).filter((card, index) => isOdd(index)).map((card, index) => {
                                return <SpotlightCard key={index} card={card} onClick={() => setSingleCardToView(card)} toggleSave={toggleSave} isExpanded={false} />
                            })}
                        </div>
                    </div>}
            </div>
            {(currTab === Tabs.ALL && !singleCardToView) &&
                <button className='load-btn'>
                    <Icons.ReloadArrow />
                    load new resources
                </button>}
        </div>
    )
}

export default SpotlightPage;