import { useState } from 'react';
import Modal from '../../common/Modal';
import ErrorMessage from '../../common/ErrorMessage';
import { Megaphone } from '../../common/Icons';

import './ChatAnnouncementModal.css';

const ChatAnnouncementModal = ({ isOpen, setIsOpen, sendAnnouncement }) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const handleCancelClick = () => {
        setError(false);
        setIsOpen(false);
    }

    const handleActionClick = () => {
        if (message) {
            setError(false);
            sendAnnouncement(message);
            setMessage('');
            setIsOpen(false);
        } else {
            setError(true);
        }
    }

    return (
        <Modal className='chat-announcement-modal' isModalOpen={isOpen} setModalOpen={setIsOpen} canClose={() => true}>
            <div className='content'>
                <div className='icon-container'>
                    <Megaphone />
                </div>
                <label htmlFor='msg-field' className='instructions-text'>Type out a message to send to all participants in the meeting.</label>
                <textarea id='msg-field' className='msg-field' placeholder='Type here...' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                {error && <ErrorMessage message={'Can\'t send empty messages.'} />}
                <div className='btns-container'>
                    <button onClick={handleCancelClick} className='cancel-btn'>Cancel</button>
                    <button onClick={handleActionClick}className='action-btn'>Send announcement</button>
                </div>
            </div>
        </Modal>
    );
}

export default ChatAnnouncementModal;