import { Megaphone, X } from '../../common/Icons';

import './Tags.css';

export const AnnouncementTag = ({ type, text, onClick }) => {
    const handleClick = () => {
        if (onClick) onClick();
    }

    return (
        <>
            {type === 'button' ?
                <button className='announcement-tag' onClick={handleClick}>
                    {text}
                    <Megaphone />
                </button> :
                <div className='announcement-tag'>
                    {text}
                    <Megaphone />
                </div>}
        </>
    );
}

export const Filters = Object.freeze({
    CRISIS: 0,
    BREAK: 1
});

const FilterTagButton = ({ text, color, isActive, onClick }) => {
    return (
        <button className={`filter-tag ${color} ${isActive ? 'active' : undefined}`} onClick={onClick}>
            {text}
            {isActive && <X />}
        </button>
    );
}

const StaticFilterTag = ({ text, color }) => {
    return (
        <div className={`filter-tag ${color}`}>
            {text}
        </div>
    );
}

export const FilterTag = ({ filter, type, isActive, onClick }) => {
    if (filter === Filters.CRISIS) {
        return (
            <>
                {(type === 'button') ?
                    <FilterTagButton text='Crisis' color='red' isActive={isActive} onClick={onClick} /> :
                    <StaticFilterTag text='Crisis' color='red' />}
            </>
        );
    } else {
        return (
            <>
                {(type === 'button') ?
                    <FilterTagButton text='On Break' color='yellow' isActive={isActive} onClick={onClick} /> :
                    <StaticFilterTag text='On Break' color='yellow' />}
            </>
        );
    }
}