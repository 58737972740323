import { useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientContext, SidebarContext, ChatContext, CrisisContext } from '../contexts/zoom-context';
import ZoomVideo from '@zoom/videosdk';
import MeetingModal, { ModalTypes } from '../components/MeetingModal';
import BreakModal from '../components/BreakModal';
import ErrorModal from '../components/common/ErrorModal';
import CrisisBar from '../components/CrisisBar';
import IOButton from '../components/IOButton';
import TimeDisplay from '../components/TimeDisplay';
import Sidebar from '../components/Sidebar/Sidebar';
import MeetingButton, { MeetingButtonStates } from '../components/MeetingButton';
import VideoGallery from '../components/VideoGallery';
import VideoSingle from '../components/VideoSingle';
import Toast from '../components/Toast';
import { CameraOptionsPanel, MicrophoneOptionsPanel, AudioOptionsPanel } from '../components/IOOptionsPanel';
import { resources } from '../config/devSpotlightData';
import * as Icons from '../components/common/Icons';
import { useChat } from '../hooks/useChat';
import { usePreview } from '../hooks/usePreview';
import { useScreenShare } from '../hooks/useScreenShare';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { errorToString, logError, transformSpotlightResourceData } from '../util/util';
import {
    getTodayAtTime,
    adjustAllUserAudioVolumeLocally,
    isCurrentUser,
    includesDeviceWithId,
    Events
} from '../util/meetingHelpers';

import './MeetingRoom.css';

const DeviceTypes = Object.freeze({
    CAMERA: 0,
    MICROPHONE: 1,
    SPEAKER: 2
});

// TODO: implement video feeds when GalleryView is not enabled
const MeetingRoom = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const client = useContext(ClientContext);
    const stream = client.getMediaStream();
    const chatClient = client.getChatClient();
    const chatContextValue = useChat(client, chatClient);

    // Session states
    const { pronouns, initialCameraId, initialMicId, initialSpeakerId, startTime, durationInMin } = location.state;
    const endDate = new Date(getTodayAtTime(startTime).getTime() + durationInMin * 60 * 1000);
    const [isHost, setIsHost] = useState(client.isHost());
    const [sessionHost, setSessionHost] = useState(client.getSessionHost());
    const [inBreakoutRoom, setInBreakoutRoom] = useState(false);
    const [participantsInCrisis, setParticipantsInCrisis] = useState([]);
    const [participantsOnBreak, setParticipantsOnBreak] = useState([]);
    const [spotlightCards, setSpotlightCards] = useState(resources.map(obj => transformSpotlightResourceData(obj)));
    const [pastMeetingEnd, setPastMeetingEnd] = useState(new Date() > endDate);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarHeader, setSidebarHeaderExternally] = useState(null);

    // Waiting states
    const [isWaitingCamera, setIsWaitingCamera] = useState(false);

    const [isNetworkToastShowing, setNetworkToastShowing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalActionFunc, setModalActionFunc] = useState(() => () => { });
    const [modalActionButtonText, setModalActionButtonText] = useState('');
    const [modalCancelButtonText, setModalCancelButtonText] = useState('Cancel');
    const [modalMessageStr, setModalMessageStr] = useState('');
    const [modalInfoStr, setModalInfoStr] = useState('');
    const [modalShowTime, setModalShowTime] = useState(false);
    const [timeStr, setTimeStr] = useState('');
    const [isOnBreak, setOnBreak] = useState(false);
    const [isErrorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('An error occured with X. Please close and reopen the window to try again.');

    // Screen Share
    const shareRef = useRef(null);
    const [screenShareWithVideoElement, setScreenShareWithVideoElement] = useState(stream.isStartShareScreenWithVideoElement());
    const {
        isStartedShare,
        isReceiveShare,
        startScreenSharing,
        stopScreenSharing
    } = useScreenShare(client, stream, shareRef);

    // Audio/Video states    
    const isSAB = typeof SharedArrayBuffer === 'function';
    const isSupportGalleryView = stream.isSupportMultipleVideos();
    const [audioStarted, setAudioStarted] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);
    const [micMuted, setMicMuted] = useState(false);
    const [speakerMuted, setSpeakerMuted] = useState(false);

    const [videoDevices, setVideoDevices] = useState(stream.getCameraList());
    const [cameraId, setCameraId] = useState(initialCameraId);
    const [microphoneDevices, setMicrophoneDevices] = useState(stream.getMicList());
    const [micId, setMicId] = useState(initialMicId);
    const [speakerDevices, setSpeakerDevices] = useState(stream.getSpeakerList());
    const [speakerId, setSpeakerId] = useState(initialSpeakerId);
    const {
        volume,
        micPreviewStarted,
        speakerPreviewStarted,
        previewMic,
        previewSpeaker,
        switchPreviewMic,
        switchPreviewSpeaker
    } = usePreview(null);

    // For Safari
    const [audioDecode, setAudioDecode] = useState(false);
    const [audioEncode, setAudioEncode] = useState(false);

    let cameraPanelProps = {
        deviceList: videoDevices,
        activeDeviceId: cameraId,
        switchSource: (deviceId) => switchDevice(DeviceTypes.CAMERA, deviceId)
    };
    let microphonePanelProps = {
        deviceList: microphoneDevices,
        activeDeviceId: micId,
        switchSource: (deviceId) => switchDevice(DeviceTypes.MICROPHONE, deviceId),
        previewStarted: micPreviewStarted,
        previewDevice: previewMic,
        currVolume: volume
    };
    let speakerPanelProps = {
        deviceList: speakerDevices,
        activeDeviceId: speakerId,
        switchSource: (deviceId) => switchDevice(DeviceTypes.SPEAKER, deviceId),
        previewStarted: speakerPreviewStarted,
        previewDevice: previewSpeaker,
        currVolume: volume
    };

    const crisisContextValue = {
        isHost,
        participantsInCrisis,
        crisisCount: participantsInCrisis.length,
        setSidebarOpen,
        setSidebarHeaderExternally,
        setChatUserId: chatContextValue.setChatUserId
    };

    const sidebarContextValue = {
        sidebarOpen,
        setSidebarOpen,
        sidebarHeader,
        setSidebarHeaderExternally,
        isWaitingRoom: false,
        isHost,
        sessionHost,
        openModal,
        openErrorModal,
        spotlightCards,
        setSpotlightCards,
        isUserInCrisis,
        isUserOnBreak,
        isScreenSharing: isStartedShare,
        startScreenSharing,
        stopScreenSharing
    };

    // Set modal props based on action, open the modal
    function openModal(action, actionFunc, infoStr) {
        if (action === ModalTypes.LEAVE) {
            setModalActionFunc(() => leaveSessionAndNavigateAway);
            setModalActionButtonText('Leave Meeting');
            setModalCancelButtonText('Cancel');
            setModalMessageStr('Are you sure you want to leave the meeting?');
            setModalInfoStr(pastMeetingEnd ? 'The meeting has gone over:' : 'The meeting is scheduled to end in:');
            setModalShowTime(true);
        } else if (action === ModalTypes.END) {
            setModalActionFunc(() => () => leaveSessionAndNavigateAway(true));
            setModalActionButtonText('End Meeting');
            setModalCancelButtonText('Cancel');
            setModalMessageStr('Are you sure you want to leave the meeting?');
            setModalInfoStr(pastMeetingEnd ? 'The meeting has gone over:' : 'The meeting is scheduled to end in:');
            setModalShowTime(true);
        } else if (action === ModalTypes.SEND_ON_BREAK) {
            setModalActionFunc(() => actionFunc);
            setModalActionButtonText('Send on Break');
            setModalCancelButtonText('Cancel');
            setModalMessageStr('Are you sure you want to send these participants on break?');
            setModalInfoStr(infoStr);
            setModalShowTime(false);
        } else if (action === ModalTypes.TAKE_BREAK) {
            setModalActionFunc(() => startBreak);
            setModalActionButtonText('Allow');
            setModalCancelButtonText('Not Now');
            setModalMessageStr('Taking a break will mute your microphone, turn off your camera and decrease the meeting volume to 5%');
            setModalInfoStr(undefined);
            setModalShowTime(false);
        } else if (action === ModalTypes.REMOVE) {
            setModalActionFunc(() => actionFunc);
            setModalActionButtonText('Remove');
            setModalCancelButtonText('Cancel');
            setModalMessageStr('Are you sure you want to remove these participants from the meeting?');
            setModalInfoStr(infoStr);
            setModalShowTime(false);
        }

        setIsModalOpen(true);
    }

    function openErrorModal(error) {
        console.error(error);
        setErrorMessage(errorToString(error));
        setErrorModalOpen(true);
    }

    async function updateDeviceLists(type) {
        if (type === DeviceTypes.CAMERA || !type) {
            const cameraList = stream.getCameraList();
            setVideoDevices(cameraList);
            if ((cameraList.length > 0) && !includesDeviceWithId(cameraList, cameraId)) {
                console.log('active camera was removed');
            }
        }
        if (type === DeviceTypes.MICROPHONE || !type) {
            const micList = stream.getMicList();
            setMicrophoneDevices(micList);
            if ((micList.length > 0) && !includesDeviceWithId(micList, micId)) {
                console.log('active mic was removed');
            }
        }
        if (type === DeviceTypes.SPEAKER || !type) {
            const speakerList = stream.getSpeakerList();
            setSpeakerDevices(speakerList);
            if ((speakerList.length > 0) && !includesDeviceWithId(speakerList, speakerId)) {
                console.log('active speaker was removed');
            }
        }
    }

    function refreshSourceList(type) {
        if ((type === DeviceTypes.CAMERA && videoDevices.length === 0) ||
            (type === DeviceTypes.MICROPHONE && microphoneDevices.length === 0) ||
            (type === DeviceTypes.SPEAKER && speakerDevices.length === 0)) {
            updateDeviceLists(type);
        }
    }

    // Function is called when a new source is clicked on in the options panel
    async function switchDevice(type, deviceId) {
        try {
            if (type === DeviceTypes.CAMERA) {
                setCameraId(deviceId);
                if (client.getCurrentUserInfo().bVideoOn) {
                    return stream.switchCamera(deviceId);
                }
            } else if (type === DeviceTypes.MICROPHONE) {
                setMicId(deviceId);
                switchPreviewMic(deviceId);
                return stream.switchMicrophone(deviceId);
            } else if (type === DeviceTypes.SPEAKER) {
                setSpeakerId(deviceId);
                switchPreviewSpeaker(deviceId);
                return stream.switchSpeaker(deviceId);
            }
        } catch (error) {
            // TODO: handle unable to switch devices
            console.error(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    // Start or stop user's video and handle rendering
    const toggleVideo = async () => {
        setIsWaitingCamera(true);
        if (stream.isCapturingVideo()) {
            try {
                await stream.stopVideo();
                setVideoStarted(false);
            } catch (error) {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            } finally {
                setIsWaitingCamera(false);
            }
        } else {
            let videoOptions = {
                mirrored: true
            };
            if (cameraId) {
                videoOptions.cameraId = cameraId;
            }
            try {
                await stream.startVideo(videoOptions);
                setVideoStarted(true);
            } catch (error) {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            } finally {
                setIsWaitingCamera(false);
            }
        }
    }

    // Start audio stream
    const startAudio = async () => {
        if (window.safari) {
            if (!(audioEncode && audioDecode)) {
                console.log('safari audio has not finished initializing');
                return;
            }
        }

        let audioOptions = {};
        if (micId) {
            audioOptions.microphoneId = micId;
            switchPreviewMic(micId);
        }
        if (speakerId) {
            audioOptions.speakerId = speakerId;
            switchPreviewSpeaker(speakerId);
        }

        try {
            await stream.startAudio(audioOptions);
            setAudioStarted(true);
            setMicMuted(stream.isAudioMuted());
        } catch (error) {
            openErrorModal(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    // Mute/unmute microphone
    const toggleMicrophone = async () => {
        if (client.getCurrentUserInfo().audio === '') return;

        try {
            if (client.getCurrentUserInfo().muted) {
                await stream.unmuteAudio();
                setMicMuted(false);
            } else if (client.getCurrentUserInfo().muted === false) {
                console.log('try and mute audio');
                await stream.muteAudio();
                setMicMuted(true);
            }
        } catch (error) {
            openErrorModal(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    // Locally mute/unmute all users; effectively mutes/unmutes session audio
    const toggleSpeaker = async () => {
        if (client.getCurrentUserInfo().audio === '') return;

        try {
            if (speakerMuted) {
                await stream.unmuteAllUserAudioLocally();
                setSpeakerMuted(false);
            } else {
                await stream.muteAllUserAudioLocally();
                setSpeakerMuted(true);
            }
        } catch (error) {
            openErrorModal(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    // Note: if user goes on break and their speaker is already muted, this will not unmute their speaker
    async function startBreak() {
        if (client.getCurrentUserInfo().muted === false) await toggleMicrophone();
        if (client.getCurrentUserInfo().bVideoOn) await toggleVideo();
        await adjustAllUserAudioVolumeLocally(client, 5).catch(error => {
            console.error(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        });
        if (client.getSessionHost()) {
            await client.getCommandClient().send(Events.TAKE_BREAK_NOTIF, client.getSessionHost().userId).catch(error => {
                console.log(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
        }
        setOnBreak(true);
    }

    async function returnFromBreak() {
        await adjustAllUserAudioVolumeLocally(client, 100);
        if (client.getSessionHost()) {
            await client.getCommandClient().send(Events.RETURN_FROM_BREAK_NOTIF, client.getSessionHost().userId).catch(error => {
                console.log(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
        }
        setOnBreak(false);
    }

    function isUserOnBreak(userId) {
        return !!participantsOnBreak.find(user => user.userId === userId);
    }

    function isUserInCrisis(userId) {
        return !!participantsInCrisis.find(user => user.userId === userId);
    }

    // If param end is true and user is host, end the session for everyone as well
    const leaveSession = async (end) => {
        try {
            if (client.getCurrentUserInfo().audio !== '') {
                await stream.stopAudio();
            }
            if (client.getCurrentUserInfo().bVideoOn) {
                await stream.stopVideo();
            }
            if (client.getCurrentUserInfo().sharerOn) {
                await stopScreenSharing();
            }

            if (end && client.isHost()) {
                await client.leave(end);
            } else {
                await client.leave();
            }
            location.state = {};
        } catch (error) {
            // User is about to navigate away, ignore errors
            console.error(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    const leaveSessionAndNavigateAway = async (end) => {
        await leaveSession(end);
        navigate('/', { state: {} });
    }

    const leaveBreakoutRoom = () => {
        console.log('stub::returning to main session...');
    }

    // Event listener for handling user navigating away or closing tab
    const handleBeforeUnload = async (e) => {
        if (client.getSessionInfo().isInMeeting) {
            await leaveSession();
        }
        ZoomVideo.destroyClient();
    }

    // Event listener for custom command channel events
    // 1. Host turning off client's camera
    // 2. Host sending client on break
    // 3. Participant signalling/cancelling a crisis to the host
    const handleCommandMessage = async (payload) => {
        if (payload.text === Events.TURN_OFF_CAMERA_CMD && stream.isCapturingVideo()) {
            return toggleVideo();
        }
        else if (payload.text === Events.TAKE_BREAK_CMD) {
            return startBreak();
        }
        else if (payload.text === Events.TAKE_BREAK_NOTIF) {
            console.log('recieved break notif');
            const sender = client.getUser(Number(payload.senderId));
            if (sender) setParticipantsOnBreak(prev => [...prev, sender]);
        }
        else if (payload.text === Events.RETURN_FROM_BREAK_NOTIF) {
            console.log('recieved returned from break notif');
            setParticipantsOnBreak(prev => prev.filter((user) => user.userId !== Number(payload.senderId)));
        }
        else if (payload.text === Events.CRISIS_NOTIF) {
            const sender = client.getUser(Number(payload.senderId));
            if (sender) setParticipantsInCrisis(prev => [...prev, sender]);
        }
        else if (payload.text === Events.CANCEL_CRISIS_NOTIF) {
            setParticipantsInCrisis(prev => prev.filter((user) => user.userId !== Number(payload.senderId)));
        }
    }

    // Event listener for users in crisis/on break leaving the meeting
    const onUserRemoved = (updatedParticipants) => {
        const idsToRemove = updatedParticipants.map(user => user.userId);
        setParticipantsInCrisis(prev => prev.filter((user) => !idsToRemove.includes(user.userId)));
        setParticipantsOnBreak(prev => prev.filter((user) => !idsToRemove.includes(user.userId)));
    }

    // Event listener for host/manager muting current user
    const onUserUpdated = (updatedParticipants) => {
        for (const updatedProps of updatedParticipants) {
            if (isCurrentUser(client, updatedProps) && updatedProps.hasOwnProperty('muted')) {
                setMicMuted(updatedProps.muted);
            }
        }
    }

    // Event listener to see when desktop Safari has initialized audio
    const checkEncodeDecode = (payload) => {
        if (payload.type === 'audio' && payload.result === 'success') {
            if (payload.action === 'encode') {
                // encode for sending audio stream (speak)
                setAudioEncode(true);
            } else if (payload.action === 'decode') {
                // decode for receiving audio stream (hear)
                setAudioDecode(true);
            }
        }
    }

    // Event listener to handle hot plugging and unplugging of AV devices
    // The 'device-change' event listener fires when an AV device is added or removed, and when video or audio streams start or stop
    const onDeviceChange = async () => {
        console.log('device-change fired');
        return updateDeviceLists();
    }

    // Event listener to detect session end, user kicked, or connection issues
    // TODO: change to loading state and convey to the user when needed
    const onConnectionChange = (payload) => {
        if (payload.state === 'Closed') {
            // session ended by the host or the SDK kicked the user from the session (use payload.reason to see why the SDK kicked the user)
            console.log('meeting ended/user kicked');
            navigate('/');
        } else if (payload.state === 'Reconnecting') {
            // the client side has lost connection with the server (like when driving through a tunnel)
            // will try to reconnect for a few minutes
            console.log('reconnecting...');
        } else if (payload.state === 'Connected') {
            // SDK reconnected the session after a reconnecting state
            console.log('reconnected!');
        } else if (payload.state === 'Fail') {
            // session failed to reconnect after a few minutes
            // user flushed from Zoom Video SDK session
            console.log('failed to reconnect');
            navigate('/');
        }
    }

    const onNetworkQualityChange = (payload) => {
        console.log(JSON.stringify(payload));
        const { level, userId } = payload;
        if (client.getCurrentUserInfo()?.userId === Number(userId) && (level === 0 || level === 1)) {
            console.log('poor connection!');
            setNetworkToastShowing(true);
        }
    }

    useParticipantsChange(client, () => {
        setSessionHost(client.getSessionHost());
        setIsHost(isCurrentUser(client, client.getSessionHost()));
    });

    // Apply and remove background color from body tag on entering/exiting meeting room
    useEffect(() => {
        const colors = getComputedStyle(document.getElementById('root'));
        document.body.style.backgroundColor = colors.getPropertyValue('--light-blue-2');
        return () => document.body.style.backgroundColor = 'transparent';
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        client.on('command-channel-message', handleCommandMessage);
        client.on('user-removed', onUserRemoved);
        client.on('user-updated', onUserUpdated);
        client.on('media-sdk-change', checkEncodeDecode);
        client.on('device-change', onDeviceChange);
        client.on('connection-change', onConnectionChange);
        client.on('network-quality-change', onNetworkQualityChange);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            client.off('command-channel-message', handleCommandMessage);
            client.off('user-removed', onUserRemoved);
            client.off('user-updated', onUserUpdated);
            client.off('media-sdk-change', checkEncodeDecode);
            client.off('device-change', onDeviceChange);
            client.off('connection-change', onConnectionChange);
            client.off('network-quality-change', onNetworkQualityChange);
        }
    }, []);

    return (
        <>
            <Toast
                message={'Your network connection is unstable. Video call quality may be affected.'}
                isShown={isNetworkToastShowing}
                onClose={() => setNetworkToastShowing(false)}
            />
            <MeetingModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                doAction={modalActionFunc}
                actionButtonText={modalActionButtonText}
                cancelButtonText={modalCancelButtonText}
                messageStr={modalMessageStr}
                infoStr={modalInfoStr}
                timeStr={timeStr}
                showTime={modalShowTime}
            />
            <BreakModal
                isOpen={isOnBreak}
                setIsOpen={setOnBreak}
                onClick={returnFromBreak}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                setIsOpen={setErrorModalOpen}
                message={errorMessage}
                onClick={leaveSession}
            />
            <div className={'meeting-room'}>
                <div className='header-container'><div className='header'>
                    <CrisisContext.Provider value={crisisContextValue}>
                        <CrisisBar />
                    </CrisisContext.Provider>
                    {(process.env.NODE_ENV === 'development') &&
                        <div className='debug-btns'>
                            <button onClick={() => setNetworkToastShowing(true)}>Show Toast</button>
                            {/* <button onClick={() => { throw new Error() }}>Throw Error</button> */}
                        </div>}
                </div></div>
                <div className='meeting-content-container'>
                    {isSupportGalleryView ? <VideoGallery /> : <VideoSingle />}
                    <SidebarContext.Provider value={sidebarContextValue}>
                        <ChatContext.Provider value={chatContextValue}>
                            <Sidebar />
                        </ChatContext.Provider>
                    </SidebarContext.Provider>
                </div>
                <div className='footer-container'><div className='footer'>
                    <div className='io-buttons-container'>
                        <IOButton
                            type='video'
                            onClick={toggleVideo}
                            disableOnOffToggle={isWaitingCamera}
                            onOptionsOpen={() => refreshSourceList(DeviceTypes.CAMERA)}
                            isOnParent={videoStarted}
                            OnIcon={Icons.CameraOn}
                            OffIcon={Icons.CameraOff}
                            IOOptionsPanel={CameraOptionsPanel}
                            panelProps={cameraPanelProps}
                        />
                        <IOButton
                            type='microphone'
                            onClick={toggleMicrophone}
                            isOnParent={!micMuted}
                            disableButton={!audioStarted}
                            OnIcon={Icons.MicrophoneOn}
                            OffIcon={Icons.MicrophoneOff}
                            IOOptionsPanel={MicrophoneOptionsPanel}
                            panelProps={microphonePanelProps}
                        />
                        <IOButton
                            type='speaker'
                            onClick={toggleSpeaker}
                            isOnParent={!speakerMuted}
                            disableButton={!audioStarted}
                            OnIcon={Icons.SpeakerOn}
                            OffIcon={Icons.SpeakerOff}
                            IOOptionsPanel={AudioOptionsPanel}
                            panelProps={speakerPanelProps}
                        />
                        <button className='cc-btn' onClick={() => console.log('closed captions')}>
                            <Icons.ClosedCaptionsIcon />
                        </button>
                        {!audioStarted &&
                            <div className='connect-inputs-container'>
                                <label className='connect-inputs-label'>Inputs not connected</label>
                                <button className='connect-inputs-btn' onClick={startAudio}>
                                    Connect Inputs
                                </button>
                            </div>
                        }
                    </div>
                    <div className='time-btns-container'>
                        <TimeDisplay isPastTarget={pastMeetingEnd} setIsPastTarget={setPastMeetingEnd} targetDate={endDate} sendTimeStringToParent={setTimeStr} />
                        {!isHost && <MeetingButton onClick={() => openModal(ModalTypes.TAKE_BREAK)} status={MeetingButtonStates.BREAK} />}
                        {inBreakoutRoom ?
                            <MeetingButton onClick={leaveBreakoutRoom} status={MeetingButtonStates.LEAVE_BREAKOUT} /> :
                            <>
                                {isHost ?
                                    <MeetingButton onClick={() => openModal(ModalTypes.END)} status={MeetingButtonStates.END} /> :
                                    <MeetingButton onClick={() => openModal(ModalTypes.LEAVE)} status={MeetingButtonStates.LEAVE} />
                                }
                            </>
                        }
                    </div>
                </div></div>
            </div>
        </>
    );
}

export default MeetingRoom;