import { useCallback, useContext } from 'react';
import { CrisisContext } from '../contexts/zoom-context';
import { Headers } from './Sidebar/Sidebar';
import ParticipantList from './ParticipantList';
import { ExclamationTriangle } from './common/Icons';
import './CrisisBarPopup.css';

const CrisisBarPopup = () => {
    const {
        crisisCount,
        participantsInCrisis,
        setSidebarOpen,
        setSidebarHeaderExternally,
        setChatUserId
    } = useContext(CrisisContext);

    const openUserInChat = useCallback((userId) => {
        setChatUserId(userId);
        setSidebarHeaderExternally(Headers.CHAT);
        setSidebarOpen(true);
    }, [setSidebarOpen, setSidebarHeaderExternally, setChatUserId]);

    if (crisisCount === 0) {
        return (
            <div className='crisis-popup empty'>
                There are currently no participants in crisis mode.
            </div>
        );
    }

    return (
        <div className='crisis-popup'>
            <div className='warning'>
                <ExclamationTriangle />
                <div>{`${crisisCount} participants are currently in crisis mode.`}</div>
            </div>
            <ParticipantList
                participantList={participantsInCrisis}
                showAVStatus={false}
                showMessageButton={true}
                onMessageButtonClick={openUserInChat}
            />
        </div>
    );
}

export default CrisisBarPopup;