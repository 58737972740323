import React, { useState, useEffect } from 'react';
import { ref, push, set, get } from 'firebase/database';
import { database } from '../firebase';
import { useParams, Link } from 'react-router-dom';
import './PeerSupportForm.css';

export default function ProgramRegistration() {
  const { programName: programNameWithHyphen } = useParams();
  const programName = programNameWithHyphen.replace(/-/g, ' ');

  const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      referral: '',
      newsletter: '',
      consent: '',
      programName: programName
  });

  const [submitted, setSubmitted] = useState(false); // New state to track submission status

  const [description, setDescription] = useState('');
  const [cadence, setCadence] = useState('');

  useEffect(() => {
      if (programName) {
          const dbRef = ref(database, `programs/${programName}`);
          console.log(programName);
          get(dbRef).then((snapshot) => {
              if (snapshot.exists()) {
                  const data = snapshot.val();
                  setDescription(data.description);
                  setCadence(data.cadence);
                  console.log(description);
                  console.log(cadence);
              } else {
                  console.log("No data available");
              }
          }).catch((error) => {
              console.error(error);
          });
      }
  }, [programName]);
      
  const isFormValid = () => {
    return formData.name && formData.email && formData.phone && formData.referral && formData.newsletter && formData.consent;
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
        alert("Please fill all the fields.");
        return;
    }
    if (!programName) {
        console.error('Program name is not set');
        return;
    }

    // Construct the database path dynamically
    const programPath = `programregistrations/`;
    const dbRef = ref(database, programPath);
    const newEntryRef = push(dbRef);

    set(newEntryRef, formData)
        .then(() => {
            alert("Thank you for submitting the form! Check your inbox for confirmation.");
            console.log('Data sent to Firebase');
        })
        .catch((error) => {
            alert("Error submitting the form. Please try again.");
            console.error('Error sending data to Firebase', error);
        });

        setSubmitted(true); // Set the submission state to true
  };

  if (submitted) {
    // Display confirmation message and back button when form is submitted
    return (
        <div className="confirmationContainer">
            <p className="confirmationMessage">
                Their registration has been submitted and please look out for an email from the Krasman Centre.
            </p>
            <Link to="https://krasmancentre.peersupport.io/explore" className="submitButton">
                Back to Explore
            </Link>
        </div>
    );
  }

    
  const getDescription = () => {
    const style = {
        textAlign: 'left', 
        color: '#4E2375', 
        fontSize: '16px', 
        borderLeft: '5px solid #4E2375',
        padding: '15px',
        backgroundColor: '#f9f9f9',
        borderRadius: '5px',
        lineHeight: '1.6'
    };
    
    return (
        <div>
            <p style={style}>{description}</p>
            <p style={style}>{cadence}</p>
        </div>
      );
  };
    

  return (
    <div className="screenContainer">
      <div className="blobContainer1">
        <div className="blob blob1">
          <svg xmlns="http://www.w3.org/2000/svg" width="252" height="287" viewBox="0 0 222 247" fill="none">
            <path opacity="0.2" d="M20.4662 231.255C-29.4768 204.146 23.885 8.22677 88.7111 2.59078C146.254 -21.3061 215.424 150.298 221.287 189.76C228.616 239.088 82.895 265.14 20.4662 231.255Z" fill="url(#paint0_linear_430_554)"/>
            <defs>
              <linearGradient id="paint0_linear_430_554" x1="147.004" y1="244.686" x2="23.6683" y2="16.7151" gradientUnits="userSpaceOnUse">
                <stop offset="0.0798461" stop-color="#C53EF6"/>
                <stop offset="0.671875" stop-color="#FF7461"/>
                <stop offset="1" stop-color="#FF7461" stop-opacity="0.56"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="blob blob2">
          <svg xmlns="http://www.w3.org/2000/svg" width="252" height="287" viewBox="0 0 260 250" fill="none">
            <path opacity="0.2" d="M184.179 3.15582C253.393 19.5636 285.87 190.392 232.64 234.416C174.886 282.182 35.5613 204.053 12.798 170.964C-16.2889 128.683 11.7747 78.9886 53.624 40.6436C88.3008 4.48182 150.575 -4.81027 184.179 3.15582Z" fill="url(#paint0_linear_430_555)"/>
            <defs>
              <linearGradient id="paint0_linear_430_555" x1="250.767" y1="120.899" x2="-22.5965" y2="165.647" gradientUnits="userSpaceOnUse">
                <stop offset="0.119792" stop-color="#FE439D"/>
                <stop offset="0.59375" stop-color="#C53EF6"/>
                <stop offset="1" stop-color="#C53EF6" stop-opacity="0.28"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="blobContainer">
        <div className="blob blob1">
          <svg xmlns="http://www.w3.org/2000/svg" width="252" height="287" viewBox="0 0 222 247" fill="none">
            <path opacity="0.2" d="M20.4662 231.255C-29.4768 204.146 23.885 8.22677 88.7111 2.59078C146.254 -21.3061 215.424 150.298 221.287 189.76C228.616 239.088 82.895 265.14 20.4662 231.255Z" fill="url(#paint0_linear_430_554)"/>
            <defs>
              <linearGradient id="paint0_linear_430_554" x1="147.004" y1="244.686" x2="23.6683" y2="16.7151" gradientUnits="userSpaceOnUse">
                <stop offset="0.0798461" stop-color="#C53EF6"/>
                <stop offset="0.671875" stop-color="#FF7461"/>
                <stop offset="1" stop-color="#FF7461" stop-opacity="0.56"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="blob blob2">
          <svg xmlns="http://www.w3.org/2000/svg" width="252" height="287" viewBox="0 0 260 250" fill="none">
            <path opacity="0.2" d="M184.179 3.15582C253.393 19.5636 285.87 190.392 232.64 234.416C174.886 282.182 35.5613 204.053 12.798 170.964C-16.2889 128.683 11.7747 78.9886 53.624 40.6436C88.3008 4.48182 150.575 -4.81027 184.179 3.15582Z" fill="url(#paint0_linear_430_555)"/>
            <defs>
              <linearGradient id="paint0_linear_430_555" x1="250.767" y1="120.899" x2="-22.5965" y2="165.647" gradientUnits="userSpaceOnUse">
                <stop offset="0.119792" stop-color="#FE439D"/>
                <stop offset="0.59375" stop-color="#C53EF6"/>
                <stop offset="1" stop-color="#C53EF6" stop-opacity="0.28"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="formContainer">
        <button className="backButton" onClick={() => {
              window.open("https://krasmancentre.com", "_blank");
          }}>
              Back to Krasman Centre Homepage
        </button>

        <p className="formHeader">
          Program Registration Form: {programName}
        </p>

        {getDescription()}

        <h3 className="heading"> Section one: Required Contact Information</h3>
        <input
          type="text"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <input
          type="email"
          placeholder="Email address"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="123-456-7890"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
        
        <h3 className="heading">Section two: How did you hear about us?</h3>
        <textarea
          className="formTextarea"
          value={formData.referral}
          onChange={(e) => setFormData({ ...formData, referral: e.target.value })}
          rows="5"
        />

        <h3 className="heading">Section three: Would you like to sign up for Krasman Centre's E-Newsletter for more information about us?</h3>
        <label className="custom-radio">
          <input
            type="radio"
            name="newsletter"
            value="yes"
            className="radio-input"
            checked={formData.newsletter === 'yes'}
            onChange={(e) => setFormData({ ...formData, newsletter: e.target.value })}
          />
          <div className="radio-display"></div> {/* Custom radio display */}
          Yes
        </label>
        <label className="custom-radio">
          <input
            type="radio"
            name="newsletter"
            value="no"
            className="radio-input"
            checked={formData.newsletter === 'no'}
            onChange={(e) => setFormData({ ...formData, newsletter: e.target.value })}
          />
          <div className="radio-display"></div> {/* Custom radio display */}
          No thank you
        </label>

        <h3 className="heading">Section four: By registering for this group, and in keeping with the Krasman Centre Virtual Group Comfort Agreement, I agree to support Krasman Centre efforts in maintaining this group as an equitable, mutually respectful and inclusive space</h3>
        <label className="custom-radio">
          <input
            type="radio"
            name="consent"
            value="yes"
            className="radio-input"
            checked={formData.consent === 'yes'}
            onChange={(e) => setFormData({ ...formData, consent: e.target.value })}
          />
          <div className="radio-display"></div> {/* Custom radio display */}
          Yes
        </label>

        <p style={{ fontSize: '15px', textAlign: 'left' }}>
         We'll send you an email shortly with all the information you need to join the session. <br /> Note: The information is kept strictly confidential and secure.<br />
         Please click here to access our Virtual Group Comfort Agreement. Please click here for land acknowledgement information.
        </p>

        <button
          className="submitButton"
          variant="contained"
          onClick={handleSubmit}
        >
          Register for the program
        </button>
      </div>
    </div>
  );
}
