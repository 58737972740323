import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './SignIn.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        // Display an alert to the user
        alert(`Sorry! Those credentials do not match our records.`);
        console.error('Error signing in:', error);
      });
  };

  return (
    <div className="signin-container">
      <div className="left-column">
        <img src="krasman.png" alt="Background" className="logo"/>
        <h2 className="sign-in-text">Welcome to the Krasman Centre</h2>
        <form onSubmit={handleSignIn}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="email-input"
          />
          <button type="submit" className="sign-in-button">Sign in</button>
        </form>
      </div>
      <div className="right-column"></div>
    </div>
  );
};

export default SignIn;
