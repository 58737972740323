import { CaretRight, CaretLeft } from '../common/Icons';

import './SidebarToggle.css';

const SidebarToggle = ({ isOpen, setIsOpen }) => {
    return (
        <button className='vc-sidebar-toggle' onClick={() => setIsOpen(!isOpen)}>
            <div className='icon-container'>
                {isOpen ?
                    <CaretRight /> :
                    <CaretLeft />
                }
            </div>
        </button>
    );
}

export default SidebarToggle;