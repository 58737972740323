import { useEffect, useState } from 'react';
import './MeetingButton.css';

export const MeetingButtonStates = Object.freeze({
    START_INACTIVE: 0,
    START_ACTIVE: 1,
    LEAVE: 2,
    BREAK: 3,
    END: 4,
    LEAVE_BREAKOUT: 5
});

function getClassNameFromState(state) {
    switch (state) {
        case MeetingButtonStates.START_INACTIVE:
            return 'inactive';
        case MeetingButtonStates.START_ACTIVE:
            return 'active';
        case MeetingButtonStates.LEAVE:
            return 'leave';
        case MeetingButtonStates.BREAK:
            return 'break';
        case MeetingButtonStates.END:
            return 'end';
        case MeetingButtonStates.LEAVE_BREAKOUT:
            return 'leave';
        default:
            console.log('Unknown MeetingButton state');
            return 'inactive';
    }
}

function getButtonTextFromState(state) {
    if (state === MeetingButtonStates.LEAVE) {
        return 'Leave Meeting';
    } else if (state === MeetingButtonStates.BREAK) {
        return 'Take a Break';
    } else if (state === MeetingButtonStates.END) {
        return 'End Meeting';
    } else if (state === MeetingButtonStates.LEAVE_BREAKOUT) {
        return 'Leave Breakout Room';
    } else {
        return 'Join Meeting';
    }
}

const MeetingButton = ({ disable, onClick, status }) => {
    const [buttonText, setButtonText] = useState(() => getButtonTextFromState(status));

    useEffect(() => {
        setButtonText(getButtonTextFromState(status));
    }, [status]);

    return (
        <button
            className={`meeting-button ${getClassNameFromState(status)}`}
            onClick={onClick}
            disabled={status === MeetingButtonStates.START_INACTIVE || disable}
        >
            {buttonText}
        </button>
    );
}

export default MeetingButton;