import { useState, useCallback, useContext } from 'react';
import { ChatContext, SidebarContext } from '../../../contexts/zoom-context';
import { AnnouncementTag, FilterTag, Filters } from './Tags';
import ChatAnnouncementModal from './ChatAnnouncementModal';
import ChatThreadTile from './ChatThreadTile';

import './ChatSidebar.css';

const ChatSidebar = ({ isOpen }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [crisisFilterActive, setCrisisFilterActive] = useState(false);
    const [breakFilterActive, setBreakFilterActive] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        chatThreads,
        chatUser: selectedChatUser,
        setChatUserId,
        sendAnnouncement
    } = useContext(ChatContext);
    const {
        isUserInCrisis,
        isUserOnBreak
    } = useContext(SidebarContext);

    const handleSearchInputChange = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, []);

    const userSatisfiesSearch = useCallback((chatUser) => {
        return chatUser.displayName.toLowerCase().includes(searchTerm.toLowerCase());
    }, [searchTerm]);

    const userSatisfiesActiveFilters = useCallback((chatUser) => {
        if (crisisFilterActive && !isUserInCrisis(chatUser.userId)) {
            return false;
        }
        if (breakFilterActive && !isUserOnBreak(chatUser.userId)) {
            return false;
        }
        return true;
    }, [crisisFilterActive, breakFilterActive, isUserInCrisis, isUserOnBreak]);

    // comparison function to sort chat threads in descending order by latest message's timestamp
    const compareChatThreads = useCallback((threadA, threadB) => {
        return (threadB.latestMessage?.timestamp ?? 0) - (threadA.latestMessage?.timestamp ?? 0);
    }, []);

    return (
        <>
            <ChatAnnouncementModal isOpen={isModalOpen} setIsOpen={setModalOpen} sendAnnouncement={sendAnnouncement} />
            <div className={`chat-sidebar ${isOpen ? 'open' : undefined}`}>
                <div className='search-filter-controls'>
                    <input
                        id='chat-search-bar'
                        className='search-bar'
                        type='text'
                        placeholder='Search...'
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                    <div className='filters-container'>
                        <label>Filter:</label>
                        <FilterTag filter={Filters.CRISIS} type='button' isActive={crisisFilterActive} onClick={() => setCrisisFilterActive(prev => !prev)} />
                        <FilterTag filter={Filters.BREAK} type='button' isActive={breakFilterActive} onClick={() => setBreakFilterActive(prev => !prev)} />
                    </div>
                    <AnnouncementTag type='button' text='Send Announcement' onClick={() => setModalOpen(true)} />
                </div>
                <div className='msg-threads-container'>
                    {chatThreads
                        .filter((thread) => userSatisfiesSearch(thread.receiver))
                        .filter((thread) => userSatisfiesActiveFilters(thread.receiver))
                        .sort((a, b) => compareChatThreads(a, b))
                        .map((thread) => {
                            return (
                                <ChatThreadTile
                                    key={thread.receiver.userId}
                                    name={thread.receiver.displayName}
                                    timestamp={thread.latestMessage?.timestamp}
                                    msgPreview={thread.latestMessage?.message}
                                    numUnread={thread.numUnread}
                                    inCrisis={isUserInCrisis(thread.receiver.userId)}
                                    onBreak={isUserOnBreak(thread.receiver.userId)}
                                    selected={selectedChatUser?.userId === thread.receiver.userId}
                                    onClick={() => setChatUserId(thread.receiver.userId)}
                                />
                            );
                        })}
                </div>
            </div>
        </>
    );
}

export default ChatSidebar;