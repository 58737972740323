import { CaretRight, MapPin } from '../../common/Icons';
import './SpotlightCardInfoTable.css';

const SPECIAL_SECTIONS = {
    ADDRESS: 'Address',
    HOURS: 'Hours'
};

const Section = ({ sectionData }) => {
    const { title, info } = sectionData;

    const onClickLink = () => {
        // TODO: navigate to the google maps link
        console.log('go to:', info.googleMapsLink);
        navigator.clipboard.writeText(info.googleMapsLink);
    }

    // if (title === SPECIAL_SECTIONS.ADDRESS) {
    //     return (
    //         <div className='table-section'>
    //             <div className='section-title'>{title}</div>
    //             <div className='section-info-grid'>
    //                 <div className='address'>
    //                     {info.address}
    //                 </div>
    //                 <button onClick={onClickLink} className='maps-link'>
    //                     <MapPin />
    //                     Google Maps
    //                     <CaretRight />
    //                 </button>
    //             </div>
    //         </div>
    //     );
    // } else if (title === SPECIAL_SECTIONS.HOURS) {
    //     return (
    //         <div className='table-section'>
    //             <div className='section-title'>{title}</div>
    //             <div className='section-info'>
    //                 {info.intervals.map((interval) => {
    //                     return (
    //                         <div className='interval-item'>
    //                             <div className='left'>{interval.days}</div>
    //                             <div className='right'>{interval.hours.map((hour) => <div>{hour}</div>)}</div>
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //         </div>
    //     );
    // } else {
    // TODO: transform the info string which may have newline characters into seperate lines
        return (
            <div className='table-section'>
                <div className='section-title'>{title}</div>
                <div className='section-info'>
                    {info.split('\n').map((line, index) => <div key={index}>{line}</div>)}
                </div>
            </div>
        );
    // }
}

const SpotlightCardInfoTable = ({ data }) => {
    return (
        <div className='spotlight-card-info-table'>
            <div className='table-header'>{data.header}</div>
            <div className='table-content'>
                {data.sections.map((sectionData, index) => <Section key={index} sectionData={sectionData} />)}
            </div>
        </div>
    );
}

export default SpotlightCardInfoTable;