import './SpotlightTabButton.css';

const SpotlightTabButton = ({ onClick, isActive, Icon, children }) => {
    return (
        <button onClick={onClick} className={`spotlight-tab-btn ${isActive ? 'active' : 'inactive'}`}>
            <Icon />
            {children}
        </button>
    );
}

export default SpotlightTabButton;