import * as Icons from '../components/common/Icons';

/*
Resource JSON structure
{
    title: string,
    tagline: string,
    relevantTags: string[],
    description: string,
    email: string (optional),
    address: string,
    hours: string,
    languages: string,
    contact: string,
    applicationProcess: string
    eligibility: string (optional),
    requiredDocs: string (optional)
}
 */

export const resources = [
    {
        title: 'Les EssentiELLES',
        tagline: 'Bebes en Sante, avenir en sante',
        relevantTags: ['Prenatal Multivitamins', 'Dietitian Consultations', 'Baby Clothing Swap'],
        description: 'Provides support to French speaking families and those with children from birth to age two. Program includes nutrition support, bi-weekly lunches, frozen meals to go, prenatal multivitamins, dietitian consultations, drop-in, classes, baby clothing swap and networking with other French families. Located in Whitehorse; some services can be provided remotely throughout the Yukon Territory to all eligible families. Funded by Public Health Agency of Canada (PHAC) as a Canadian Prenatal Nutrition Program (CPNP).',
        email: 'pcnp@lesessentielles.ca',
        address: '3089 Third Avenue\nWhitehorse, YT, Y1A 5B3\nCanada',
        hours: '10 am to 5 pm Monday to Thursday.',
        languages: 'French, English',
        contact: '867-668-2636',
        applicationProcess: 'Drop-in',
        // icons is TEMPORARY
        icons: [Icons.ChildReaching, Icons.BreadSlice]
    },
    {
        title: 'Yukon Midwifery Program',
        tagline: 'Yukon Health and Social Services',
        relevantTags: ['Diagnostic Tests', 'Physical Health Screening', 'Prescriptions'],
        description: 'Provide safe, integrated midwifery care to pregnant people and their babies during pregnancy, birth, and up to eight weeks after the baby\'s birth. The program offers supportive, person-centred and publicly funded maternity care, with a full range of midwifery services from prenatal and labour to postpartum care, including birthing choices with a licensed and insured provider at home or in the hospital. Also provides routine diagnostic tests such as ultrasounds and lab work, prescriptions, and screening for physical, emotional, and social health. Offers referrals to other care providers and specialists, as needed. Provides care that centres on client values, experiences, culture, and traditions, including information to help a pregnant person make informed decisions. Babies are not be delivered at the clinic, as the facility is focused on pre- and post-natal care.',
        email: 'midwifery@yukon.ca',
        address: '9010 Quartz Road\nWhitehorse, YT, Y1A 2Z5\nCanada',
        hours: '8:30 am to 12 noon and 1 pm to 4:30 pm Monday to Friday.',
        languages: 'French, English',
        contact: '867-393-7120',
        applicationProcess: 'Whitehorse residents can apply for midwifery care online (service.yukon.ca/forms/en/apply-for-midwifery-services) or in-person after completing the downloadable application form (yukon.ca/en/yukon-midwifery-program-client-intake-form).',
        // icons is TEMPORARY
        icons: [Icons.ChildReaching]
    },
    {
        title: 'Teen Parent Centre',
        tagline: 'FH Collins Secondary School',
        relevantTags: ['Free Daycare', 'Breastfeeding Classes'],
        description: 'Provides support to pregnant teens and teen parents. The education program assists teens to complete high school at FH Collins Secondary School or the Individual Learning Centre, or by taking online courses. Provides free daycare for infants and toddlers. Other services include nutrition education, prenatal classes, breastfeeding classes, parenting and child development support, drug and alcohol recovery support, and life skills improvement. Also offers a weekly women’s support group; call for more information. An interview is required for registration with the centre. Funded by Public Health Agency of Canada (PHAC) as a Canadian Prenatal Nutrition Program (CPNP).',
        email: undefined,
        address: 'FH Collins Secondary School\n1001B Lewes Boulevard\nWhitehorse, YT, Y1A 3J5\nCanada',
        hours: '8:30 am to 12 noon and 1 pm to 4:30 pm Monday to Friday.',
        languages: 'French, English',
        contact: '867-667-TEEN (867-667-8665)',
        applicationProcess: 'An interview is required for registration with the centre.',
        // icons is TEMPORARY
        icons: [Icons.ChildReaching, Icons.Book]
    },
    // {
    //     title: 'Operation Come Home',
    //     tagline: 'Youth Drop-In and Resource Centre',
    //     relevantTags: ['Food banks', 'Free / Low-cost meals', 'Housing Support'],
    //     description: 'Resources for homeless youth including drop-in counseling, hot meals, referrals, and food bank access.',
    //     email: undefined,
    //     address: '150 Gloucester St, Ottawa, ON, K2P0A6',
    //     hours: '8:30am - 11:30am and 4:30pm - 7:30pm, Monday - Friday\n8:30am - 12:30pm, Saturday - Sunday',
    //     languages: 'Arabic, English, French, Portuguese, Spanish',
    //     contact: 'Drop in',
    //     applicationProcess: 'Drop in',
    //     eligibility: 'Youth 16-24 years old',
    //     requiredDocs: 'ID or Signed Consent Form',
    //     // icons is TEMPORARY
    //     icons: [Icons.BreadSlice, Icons.Bed]
    // },
    // {
    //     title: 'Sheperds of Good Hope',
    //     tagline: 'Emergency Women\'s Shelter',
    //     relevantTags: ['Homeless shelter', 'Free / Low-cost meals', 'Mental Health', 'Addiction', 'Housing Support'],
    //     description: 'Shelter for women providing meals, showers, laundry, and hygiene supplies.',
    //     email: undefined,
    //     address: '150 Gloucester St, Ottawa, ON, K2P0A6',
    //     hours: '8:30am - 11:30am and 4:30pm - 7:30pm, Monday - Friday\n8:30am - 12:30pm, Saturday - Sunday',
    //     languages: 'Arabic, English, French, Portuguese, Spanish',
    //     contact: 'Drop in',
    //     applicationProcess: 'Drop in',
    //     eligibility: 'Youth 16-24 years old',
    //     requiredDocs: 'ID or Signed Consent Form',
    //     // icons is TEMPORARY
    //     icons: [Icons.Bed]
    // }
];