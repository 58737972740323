import ZoomVideo from '@zoom/videosdk';
import { ClientContext } from './contexts/zoom-context';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WaitingRoom from './pages/WaitingRoom';
import ProtectedMeetingRoom from './pages/ProtectedMeetingRoom';
import { useEffect } from 'react';
import { logError } from './util/util';

// TODO: call ZoomVideo.checkSystemRequirements() to see if SDK is compatible with web browser
// Consider also ZoomVideo.checkFeatureRequirements()
// https://marketplacefront.zoom.us/sdk/custom/web/modules/default.html#checkSystemRequirements

function VideoConferencingApp() {
    const client = ZoomVideo.createClient();

    useEffect(() => {
        const onError = (event) => {
            console.log('window caught error');
            logError(event.error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            // Return true to prevent the default browser error handling
            // return true;
        }
        window.addEventListener('error', onError);
        return () => window.removeEventListener('error', onError);
    }, []);

    return (
        <ClientContext.Provider value={client}>
            <Routes>
                <Route path=':sessionName?' element={<WaitingRoom />} />
                <Route path='meeting/:sessionName?' element={<ProtectedMeetingRoom />} />
            </Routes>
        </ClientContext.Provider>
    );
}

export default VideoConferencingApp;