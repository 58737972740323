import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import { ExclamationTriangle } from './Icons';

import './ErrorModal.css';

const ErrorModal = ({ isOpen, setIsOpen, onClick, message }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        // TODO: implement what needs to happen after an error
        console.log('clicked exit and reopen');
        navigate('/video_conferencing', { state: {} });
    }

    return (
        <Modal className='error-modal' isModalOpen={isOpen} setModalOpen={setIsOpen}>
            <div className='error-modal-content'>
                <div className='icon-container'>
                    <ExclamationTriangle />
                </div>
                <div className='text-container'>
                    <h1>Something went wrong</h1>
                    <div>{message}</div>
                </div>
                <button onClick={handleClick}>Exit & Reopen</button>
            </div>
        </Modal>
    );
}

export default ErrorModal;