import React from 'react';
import './WorkerModal.css';

const WorkerModal = ({ isVisible, onSelect, onClose }) => {
  if (!isVisible) return null;

  const workers = ["Andrea Gooding", "Sara Nicklin", "Cathy Marengeur"];

  return (
    <div className="modal">
      <div className="modal-content">
        <h3>Select a Worker</h3>
        <ul>
          {workers.map(worker => (
            <li key={worker}>
              <button onClick={() => onSelect(worker)}>{worker}</button>
            </li>
          ))}
        </ul>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default WorkerModal;
