// Home.js
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import CenterBoxes from '../components/CenterBoxes';
import RightSidebar from '../components/RightSidebar';
import DynamicContent from '../components/DynamicContent';
import './Home.css';

const Home = () => {
  const [selectedLink, setSelectedLink] = useState(null);
  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <div className="home">
      <div className="left-sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <TopBar />
        <p>Request form link: <a> https://krasmancentre.peersupport.io/request-form </a> </p>
        <CenterBoxes selectedLink={selectedLink} onLinkClick={handleLinkClick} />
        <DynamicContent selectedLink={selectedLink} />
      </div>
      <div className="right-sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default Home;
