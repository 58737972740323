import { useContext, useState, useEffect } from 'react';
import { ClientContext, SidebarContext } from '../../contexts/zoom-context';
import ParticipantList from '../ParticipantList';
import * as Icons from '../common/Icons';
import { muteUsers, removeUsers, turnOffCamerasOfUsers, sendUsersOnBreak } from '../../util/meetingHelpers';
import { ModalTypes } from '../MeetingModal';

import './ParticipantsPage.css';

const stubParticipantList = Array.from({ length: 16 }).map((_, index) => {
    return {
        displayName: 'Participant Name',
        userId: index + 1
    };
});

// Waiting Room participants - TODO: static list fetched from db
const StaticPage = () => {
    return (
        <>
            <h1>{`Expected Participants (${stubParticipantList.length})`}</h1>
            <ParticipantList participantList={stubParticipantList} />
        </>
    );
}

const MeetingPage = ({ openModal }) => {
    const client = useContext(ClientContext);
    const stream = client.getMediaStream();
    const [participants, setParticipants] = useState(client.getAllUser());

    // if set lives here, will be reset when tab is exited in sidebar
    const [selectedSet, setSelectedSet] = useState(new Set());

    const deselectAll = () => {
        setSelectedSet(new Set());
    }

    const muteSelected = async () => {
        if (client.isHost() || client.isManager()) {
            const selectedUserIds = Array.from(selectedSet);
            await muteUsers(client, selectedUserIds);
            deselectAll();
        }
    }

    const removeSelected = async () => {
        if (client.isHost() || client.isManager()) {
            const selectedUserIds = Array.from(selectedSet);
            await removeUsers(client, selectedUserIds);
            deselectAll();
        }
    }

    const sendOnBreakSelected = async () => {
        if (client.isHost() || client.isManager()) {
            const selectedUserIds = Array.from(selectedSet);
            await sendUsersOnBreak(client, selectedUserIds);
            deselectAll();
        }
    }

    const turnOffCamerasOfSelected = async () => {
        if (client.isHost() || client.isManager()) {
            const selectedUserIds = Array.from(selectedSet);
            await turnOffCamerasOfUsers(client, selectedUserIds);
            deselectAll();
        }
    }

    const selectedParticipantsToString = () => {
        const selectedUserDisplayNames = Array.from(selectedSet).map(id => client.getUser(id).displayName);
        return selectedUserDisplayNames.join(', ');
    }

    const handleRemoveClick = () => {
        if (selectedSet.size > 0) {
            openModal(ModalTypes.REMOVE, removeSelected, selectedParticipantsToString());
        }
    }

    const handleSendOnBreakClick = () => {
        if (selectedSet.size > 0) {
            openModal(ModalTypes.SEND_ON_BREAK, sendOnBreakSelected, selectedParticipantsToString());
        }
    }

    useEffect(() => {
        const updateList = () => {
            setParticipants(client.getAllUser());
        }

        client.on('user-added', updateList);
        client.on('user-updated', updateList);
        client.on('user-removed', updateList);
        return () => {
            client.off('user-added', updateList);
            client.off('user-updated', updateList);
            client.off('user-removed', updateList);
        }
    }, []);

    return (
        <>
            <h1>Participants</h1>
            <ParticipantList
                participantList={participants}
                selectedSet={selectedSet}
                setSelectedSet={setSelectedSet}
                showAVStatus={true}
            />
            <div className='actions-panel'>
                <div className={`selected-count ${selectedSet.size === 0 ? 'hidden' : undefined}`}>
                    {`${selectedSet.size} participants selected`}
                    <button type='button' onClick={deselectAll}><Icons.X /></button>
                </div>
                <div className='action-buttons-container'>
                    <button onClick={muteSelected}>Mute</button>
                    <button onClick={handleRemoveClick}>Remove</button>
                    <button onClick={handleSendOnBreakClick}>Send on Break</button>
                    <button onClick={turnOffCamerasOfSelected}>Turn Camera Off</button>
                </div>
            </div>
        </>
    );
}

const ParticipantsPage = ({ className }) => {
    const { isWaitingRoom, openModal } = useContext(SidebarContext);

    return (
        <div className={`${className} participants`}>
            {isWaitingRoom ?
                <StaticPage /> :
                <MeetingPage openModal={openModal} />
            }
        </div>
    );
}

export default ParticipantsPage;