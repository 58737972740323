import { ExclamationTriangle } from './Icons';

import './ErrorMessage.css';

const ErrorMessage = ({ message }) => {
    return (
        <div className='error-message-container'>
            <div className='error-message'>
                    <ExclamationTriangle />
                <div>{message}</div>
            </div>
        </div>
    );
}

export default ErrorMessage;