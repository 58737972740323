import React from 'react';
import { getAuth, signOut } from 'firebase/auth'; // Importing the necessary functions
import './RightSidebar.css';

const RightSidebar = () => {

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  return (
    <div className="right-sidebar">
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <div className="notification-text">Notifications</div>
      { /* for when a new ticket is created */ }
      { /* for when a followup is completed */ }
    </div>
  );
};

export default RightSidebar;
