import { AnnouncementTag } from './Tags';
import './ChatMessage.css';

const ChatMessage = ({ sentByCurrentUser, isAnnouncement, senderName, timestamp, messageBody }) => {
    return (
        <div className={`message ${sentByCurrentUser ? 'sent-msg' : 'received-msg'}`}>
            {isAnnouncement && <AnnouncementTag text='Meeting Announcement' />}
            <div className='info'>
                <div className='sender-name'>{senderName}</div>
                <div className='timestamp'>{timestamp}</div>
            </div>
            <div className='body'>
                {messageBody}
            </div>
        </div>
    );
}

export default ChatMessage;