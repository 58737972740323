import { useEffect } from 'react';
import * as Icons from './common/Icons';
import VisualizeAudioBar from './VisualizeAudioBar';

import './IOOptionsPanel.css';

const IOOptionsPanel = ({ isOpen, headerText, children }) => {
    return (
        <div className={`io-options-panel ${isOpen ? 'open' : 'closed'}`}>
            <h1>{headerText}</h1>
            {children}
        </div>
    );
}

const SourceList = ({ deviceList, activeDeviceId, switchSource, disableButtons }) => {
    const onSourceClick = (device) => {
        if (disableButtons) return;
        if (device.deviceId === activeDeviceId) return;
        switchSource(device.deviceId);
    }

    if (deviceList.length === 0) {
        return (
            <div className='source-list'>
                No sources found
            </div>
        );
    }

    return (
        <ul className='source-list'>
            {deviceList.map((device, index) => {
                return (
                    <li key={index} className={(device.deviceId === activeDeviceId) ? 'selected' : undefined} onClick={() => onSourceClick(device)}>
                        <div className='label-container'>
                            {device.label}
                        </div>
                        <div className='icon-container'>
                            {(device.deviceId === activeDeviceId) && <Icons.Checkmark />}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export const CameraOptionsPanel = ({ isOpen, disablePreviewAndSourceButtons, panelProps }) => {
    const {
        deviceList,
        activeDeviceId,
        switchSource
    } = panelProps;

    return (
        <IOOptionsPanel isOpen={isOpen} headerText='Select Camera Source'>
            <SourceList deviceList={deviceList} activeDeviceId={activeDeviceId} switchSource={switchSource} disableButtons={disablePreviewAndSourceButtons} />
        </IOOptionsPanel>
    );
}

export const MicrophoneOptionsPanel = ({ isOpen, disablePreviewAndSourceButtons, panelProps }) => {
    const {
        deviceList,
        activeDeviceId,
        switchSource,
        previewStarted,
        previewDevice,
        currVolume
    } = panelProps;

    useEffect(() => {
        if (!isOpen && previewStarted) {
            previewDevice();
        }
    }, [isOpen]);

    return (
        <IOOptionsPanel isOpen={isOpen} headerText='Select Microphone Source'>
            <SourceList deviceList={deviceList} activeDeviceId={activeDeviceId} switchSource={switchSource} disableButtons={disablePreviewAndSourceButtons} />
            <div className='audio-bar'>
                <Icons.MicrophoneOn />
                <VisualizeAudioBar value={currVolume} />

            </div>
            <button className={`io-options-panel-button ${previewStarted ? 'testing' : undefined}`} disabled={disablePreviewAndSourceButtons} onClick={previewDevice}>
                {previewStarted ? 'Stop Test' : 'Test Audio'}
            </button>
        </IOOptionsPanel>
    );
}

export const AudioOptionsPanel = ({ isOpen, disablePreviewAndSourceButtons, panelProps }) => {
    const {
        deviceList,
        activeDeviceId,
        switchSource,
        previewStarted,
        previewDevice,
        currVolume
    } = panelProps;

    useEffect(() => {
        if (!isOpen && previewStarted) {
            previewDevice();
        }
    }, [isOpen]);

    return (
        <IOOptionsPanel isOpen={isOpen} headerText='Select Audio Source'>
            <SourceList deviceList={deviceList} activeDeviceId={activeDeviceId} switchSource={switchSource} disableButtons={disablePreviewAndSourceButtons} />
            <div className='audio-bar'>
                <Icons.SpeakerOn />
                <VisualizeAudioBar value={currVolume} />
            </div>
            <button className={`io-options-panel-button ${previewStarted ? 'testing' : undefined}`} disabled={disablePreviewAndSourceButtons} onClick={previewDevice}>
                {previewStarted ? 'Stop Test' : 'Test Audio'}
            </button>
        </IOOptionsPanel>
    );
}