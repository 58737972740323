export function getVideoPlayerContainer() {
    return document.querySelector('video-player-container');
}

export function getVideoDivArray() {
    return getVideoPlayerContainer()?.getElementsByClassName('video-div') ?? [];
}

export function assignVideoDiv(videoDiv, userId, displayName) {
    const nameTag = videoDiv?.querySelector('label');
    if (nameTag) {
        nameTag.innerHTML = displayName;
    }
    videoDiv?.setAttribute('data-user-id', userId);
}

export function unassignVideoDiv(videoDiv) {
    const nameTag = videoDiv?.querySelector('label');
    if (nameTag) {
        nameTag.innerHTML = '';
    }
    videoDiv?.setAttribute('data-user-id', null);
    setVideoDivNotPlaying(videoDiv);
}

export function getUserIdFromDiv(videoDiv) {
    return parseInt(videoDiv?.getAttribute('data-user-id'));
}

export function findVideoDivByUserId(userId) {
    return document.querySelector(`[data-user-id="${userId}"]`);
}

export function setVideoDivPlaying(videoDiv) {
    videoDiv?.setAttribute('class', 'video-div');
    videoDiv?.setAttribute('data-playing-video', true);
}

export function setVideoDivNotPlaying(videoDiv) {
    videoDiv?.setAttribute('class', 'video-div placeholder');
    videoDiv?.setAttribute('data-playing-video', false);
}