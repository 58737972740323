import ParticipantList from '../ParticipantList';
import './AbsenteesPage.css';

const stubParticipantList = Array.from({ length: 7 }).map((_, index) => {
    return {
        displayName: 'Absentee Name',
        userId: index + 1
    };
});

const AbsenteesPage = ({ className }) => {
    return (
        <div className={`${className} absentees`}>
            <h1>{`Absentees (${stubParticipantList.length})`}</h1>
            <ParticipantList participantList={stubParticipantList} />
        </div>
    );
}

export default AbsenteesPage;