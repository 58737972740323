import { FilterTag, Filters } from './Tags';

import './ChatThreadTile.css';

const ChatThreadTile = ({
    name,
    timestamp,
    msgPreview,
    numUnread,
    inCrisis,
    onBreak,
    selected,
    onClick
}) => {
    return (
        <div onClick={onClick} className={`chat-thread-tile ${selected ? 'selected' : undefined}`}>
            <div className='tile-row'>
                <div className='recipient-name'>{name}</div>
                <div className='timestamp'>{timestamp ? new Date(timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : ''}</div>
            </div>
            <div className='tile-row'>
                <div className='msg-preview'>{msgPreview ?? ''}</div>
                {numUnread > 0 && <div className='notif-dot'>{numUnread}</div>}
            </div>
            <div className='tags-container'>
                {inCrisis && <FilterTag filter={Filters.CRISIS} />}
                {onBreak && <FilterTag filter={Filters.BREAK} />}
            </div>
        </div>
    );
}

export default ChatThreadTile;