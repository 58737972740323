import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer
} from 'recharts';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import './Reports.css';
import RightSidebar from '../components/RightSidebar';

const Reports = () => {
  const [timeFrame, setTimeFrame] = useState('day');
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [programUsageData, setProgramUsageData] = useState([]);

  const dataDay = Array.from({length: 31}, (_, i) => ({ 
    name: `Nov ${i+1}`, 
    people: Math.floor(Math.random() * 100) 
  })).concat(Array.from({length: 30}, (_, i) => ({ 
    name: `Dec ${i+1}`, 
    people: Math.floor(Math.random() * 100) 
  })));

  const dataWeek = [
    { name: 'Nov Week 1', people: Math.floor(Math.random() * 500) },
    { name: 'Nov Week 2', people: Math.floor(Math.random() * 500) },
    { name: 'Nov Week 3', people: Math.floor(Math.random() * 500) },
    { name: 'Nov Week 4', people: Math.floor(Math.random() * 500) },
    { name: 'Dec Week 1', people: Math.floor(Math.random() * 500) },
    { name: 'Dec Week 2', people: Math.floor(Math.random() * 500) },
    { name: 'Dec Week 3', people: Math.floor(Math.random() * 500) },
    { name: 'Dec Week 4', people: Math.floor(Math.random() * 500) },
  ];

  const dataReasons = [
    { name: 'Anxiety', count: Math.floor(Math.random() * 200) },
    { name: 'Depression', count: Math.floor(Math.random() * 200) },
    { name: 'Stress', count: Math.floor(Math.random() * 200) },
    { name: 'Suicidal Thoughts', count: Math.floor(Math.random() * 200) },
    { name: 'Grief and Loss', count: Math.floor(Math.random() * 200) },
    { name: 'Relationship Issues', count: Math.floor(Math.random() * 200) },
    { name: 'Addiction', count: Math.floor(Math.random() * 200) },
    { name: 'Self-Harm', count: Math.floor(Math.random() * 200) },
    { name: 'Trauma', count: Math.floor(Math.random() * 200) },
    { name: 'Eating Disorders', count: Math.floor(Math.random() * 200) },
    { name: 'Sleep Disorders', count: Math.floor(Math.random() * 200) },
    { name: 'Parenting Issues', count: Math.floor(Math.random() * 200) },
    { name: 'Anger Management', count: Math.floor(Math.random() * 200) },
    { name: 'Identity Issues', count: Math.floor(Math.random() * 200) },
    { name: 'Job Stress', count: Math.floor(Math.random() * 200) },
    { name: 'Others', count: Math.floor(Math.random() * 200) },
];

const dataReasonsTwo = [
  { name: 'Anxiety', count: Math.floor(Math.random() * 200) },
  { name: 'Depression', count: Math.floor(Math.random() * 200) },
  { name: 'Stress', count: Math.floor(Math.random() * 200) },
  { name: 'Suicidal Thoughts', count: Math.floor(Math.random() * 200) },
  { name: 'Grief and Loss', count: Math.floor(Math.random() * 200) },
  { name: 'Relationship Issues', count: Math.floor(Math.random() * 200) },
  { name: 'Addiction', count: Math.floor(Math.random() * 200) },
  { name: 'Self-Harm', count: Math.floor(Math.random() * 200) },
  { name: 'Trauma', count: Math.floor(Math.random() * 200) },
  { name: 'Eating Disorders', count: Math.floor(Math.random() * 200) },
  { name: 'Sleep Disorders', count: Math.floor(Math.random() * 200) },
  { name: 'Parenting Issues', count: Math.floor(Math.random() * 200) },
  { name: 'Anger Management', count: Math.floor(Math.random() * 200) },
  { name: 'Identity Issues', count: Math.floor(Math.random() * 200) },
  { name: 'Job Stress', count: Math.floor(Math.random() * 200) },
  { name: 'Others', count: Math.floor(Math.random() * 200) },
];


  const programs = [
    '1-1 Peer Counselling', 
    'Warm Line (Crisis Phone)', 
    'Housing First (H2H)', 
    'Veteran Support',
    'Trauma Support',
    'Senior Wellness',
    'Domestic Violence Support',
    'Sexual Assault Support',
    'Eating Disorder Support',
    'Self-Harm Support',
    'Addiction Support',
    'Anger Management',
    'Grief Counseling',
    'Family Support',
    'Youth Outreach',
    'LGBTQ+ Support',
    'Nutrition and Wellness',
    'Physical Activity'
  ];

  const allProgramUsageData = [
    { name: 'Aug', ...programs.reduce((acc, program) => ({...acc, [program]: Math.floor(Math.random() * 100) }), {}) },
    { name: 'Sep', ...programs.reduce((acc, program) => ({...acc, [program]: Math.floor(Math.random() * 100) }), {}) },
  ];

  useEffect(() => {
    if (selectedPrograms.length === 0) {
      setProgramUsageData(allProgramUsageData);
    } else {
      setProgramUsageData(allProgramUsageData.map(d => ({
        name: d.name,
        ...selectedPrograms.reduce((obj, program) => {
          obj[program] = d[program];
          return obj;
        }, {}),
      })));
    }
  }, [selectedPrograms]);

  return (
    <div className="home">
      <div className="left-sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <TopBar />
        <div className="reports">
          <h3 className="page-headline">Make your selections: (Note - This is dummy data)</h3>
          
          <div className="filters">
            <div className="timeframe-selection">
              <select onChange={(e) => setTimeFrame(e.target.value)} style={styles.selector}>
                <option value="day">Day by Day</option>
                <option value="week">Week by Week</option>
              </select>
            </div>
            
            <div className="program-selection">
              <select 
                multiple={true} 
                style={{...styles.selector, width: '400px'}} 
                onChange={(e) => setSelectedPrograms(Array.from(e.target.selectedOptions, option => option.value))}
              >
                {programs.map((program, index) => 
                  <option key={index} value={program}>{program}</option>
                )}
              </select>
            </div>
          </div>

          <h3 className="page-headline">Forecasting section</h3>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={timeFrame === 'day' ? dataDay : dataWeek} margin={styles.chartMargin}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="people" stroke="#FE439D" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={dataReasons}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#C53EF6" />
            </BarChart>
          </ResponsiveContainer>

          <h3 className="page-headline">Service Usage Numbers</h3>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={programUsageData} margin={styles.chartMargin}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {selectedPrograms.length === 0 ? programs.map((program, index) => (
                <Line key={index} type="monotone" dataKey={program} stroke="#FE439D" activeDot={{ r: 8 }} />
              )) : selectedPrograms.map((program, index) => (
                <Line key={index} type="monotone" dataKey={program} stroke="#FE439D" activeDot={{ r: 8 }} />
              ))}
            </LineChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={dataReasonsTwo}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#C53EF6" />
            </BarChart>
          </ResponsiveContainer>

        </div>
      </div>
      <div className="right-sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

const styles = {
  selector: {
    borderRadius: '12px',
    background: '#FFF0E6',
    padding: '11px',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
    width: '10vw',
  },
  chartMargin: {
    top: 20, 
    right: 50, 
    left: 0,
  }
};

export default Reports;
