import { useContext, useState } from 'react';
import { SidebarContext } from '../../contexts/zoom-context';
import ErrorMessage from '../common/ErrorMessage';
import './ScreenSharePage.css';

const ScreenSharePage = ({ className }) => {
    const { isScreenSharing, startScreenSharing, stopScreenSharing } = useContext(SidebarContext);
    const [error, setError] = useState(false);

    // Show under construction messsage while feature is being implemented
    const showError = () => {
        setError(true);
    }

    return (
        <div className={`${className} screen-share`}>
            <h1>Screenshare</h1>
            {isScreenSharing ? 
                <>
                    <div className='ss-msg-container'>
                        <div className='ss-msg'>You are currently sharing your screen.</div>
                    </div>
                    <button onClick={stopScreenSharing} className='stop-share-btn'>
                        <div className='square' />
                        Stop Sharing
                    </button>
                </> 
                : 
                <button onClick={showError} className='share-screen-btn'>Share Screen</button>
            }
            {error && <ErrorMessage message={'Sorry! The screen share feature is under construction.'} />}
        </div>
    );
}

export default ScreenSharePage;