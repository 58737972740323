import { database, functions, httpsCallable } from '../../firebase';
import { ref, push, set, serverTimestamp }  from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

export async function getToken(meetingArgs) {
    const generateSignature = httpsCallable(functions, 'generateZoomSignature');
    return generateSignature(meetingArgs).then((result) => {
        return result.data.signature;
    });
};

function errorToJson(error) {
    const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
    const deserializedError = JSON.parse(serializedError);
    return deserializedError;
}

export function logError(error, info = {}) {
    const errorObj = errorToJson(error);
    const errorLogRef = ref(database, 'errorLog');
    const newErrorLog = push(errorLogRef);
    const value = {
        timestamp: new Date().toLocaleString('en-US', { timeZone: 'Canada/Pacific' }),
        domain: window.location.hostname,
        ...info,
        error: errorObj
    };
    console.log('logging error to database:', JSON.stringify(value));
    set(newErrorLog, value);
}

export function errorToString(error) {
    if (error.hasOwnProperty('message')) {
        return error.message;
    }
    else if (error.hasOwnProperty('type') && error.hasOwnProperty('reason')) {
        return `${error.type}: ${error.reason}`;
    }
    else {
        return JSON.stringify(error);
    }
}

export function transformSpotlightResourceData(resource) {
    const {
        email,
        address,
        hours,
        languages,
        contact,
        applicationProcess
    } = resource;

    const infoTable1 = {
        header: 'Additional Information',
        sections: [
            {
                title: 'Application Process',
                info: applicationProcess
            },
            {
                title: 'Languages',
                info: languages
            }
        ]
    };

    const infoTable2 = {
        header: 'Overview',
        sections: [
            {
                title: 'Address',
                info: address
            },
            {
                title: 'Hours',
                info: hours
            },
            {
                title: 'Contact',
                info: [contact, email].join('\n')
            }
        ]
    };

    return {
        ...resource,
        id: uuidv4(),
        distance: '1 km',
        infoTables: [infoTable1, infoTable2],
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        saved: false
    };
}

export function isOdd(x) {
    return (x % 2) === 1;
}

export function isEven(x) {
    return (x % 2) === 0;
}