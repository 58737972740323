import { useState } from 'react';
import Modal from './common/Modal';

import './MeetingModal.css';

export const ModalTypes = {
    LEAVE: 0,
    END: 1,
    SEND_ON_BREAK: 2,
    TAKE_BREAK: 3,
    REMOVE: 4
};

const MeetingModal = ({
    isOpen,
    setIsOpen,
    doAction,
    onClose,
    actionButtonText,
    cancelButtonText = 'Cancel',
    messageStr,
    infoStr,
    timeStr,
    showTime,
    loadDuringAction,
    loadingText
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onClickCancel = () => {
        setIsOpen(false);
        if (onClose) onClose();
    }

    const onClickAction = async () => {
        if (loadDuringAction) {
            setIsLoading(true);
            await doAction();
            setIsLoading(false);
        } else {
            doAction();
        }
        setIsOpen(false);
    }

    return (
        <Modal className={`meeting-modal ${infoStr ? undefined : 'large'}`} isModalOpen={isOpen} setModalOpen={setIsOpen}>
            {isLoading ?
                <div className='content'>
                    {loadingText}
                </div>
                :
                <div className='content'>
                    {messageStr && <div className='message'>{messageStr}</div>}
                    {infoStr &&
                        <div className='info-text'>
                            <div>{infoStr}</div>
                            {showTime && <div>{timeStr}</div>}
                        </div>
                    }
                    <div className='button-container'>
                        <button onClick={onClickCancel} className='cancel-button'>{cancelButtonText}</button>
                        <button onClick={onClickAction} className='action-button'>{actionButtonText}</button>
                    </div>
                </div>}
        </Modal>
    );
}

export default MeetingModal;