import { useState, useEffect, useRef } from 'react';
import { OptionsDots } from './common/Icons';

import './IOButton.css';

const IOButton = ({ 
    type, 
    onClick,
    onOptionsOpen,
    isOnParent, 
    disableButton, 
    disableOnOffToggle, 
    disableOptions, 
    disablePreviewAndSourceButtons, 
    OnIcon, 
    OffIcon, 
    labelText, 
    IOOptionsPanel, 
    panelProps 
}) => {
    const thisButton = useRef(null);
    const [optionsPanelOpen, setOptionsPanelOpen] = useState(false);
    const [isOff, setIsOff] = useState(true);

    useEffect(() => {
        function closePanel(e) {
            if (optionsPanelOpen && !thisButton.current?.contains(e.target)) {
                setOptionsPanelOpen(false);
            }
        }
        document.addEventListener('mousedown', closePanel);
        return () => document.removeEventListener('mousedown', closePanel);
    }, [optionsPanelOpen]);

    useEffect(() => {
        if (isOnParent === undefined) return;
        setIsOff(!isOnParent);
    }, [isOnParent]);

    const toggleOnOff = () => {
        if (isOnParent === undefined) {
            setIsOff(!isOff);
        }
        onClick();
    }

    const toggleOptionsPanel = () => {
        if (!optionsPanelOpen && onOptionsOpen) {
            onOptionsOpen();
        }
        setOptionsPanelOpen(!optionsPanelOpen);
    }

    return (
        <div ref={thisButton} id={`${type}-io-button`} className='io-button'>
            <div className='io-options-panel-container'>
                <IOOptionsPanel isOpen={optionsPanelOpen} disablePreviewAndSourceButtons={disablePreviewAndSourceButtons} panelProps={panelProps} />
            </div>
            <div className='buttons-container'>
                <button className='on-off-button' onClick={toggleOnOff} disabled={disableOnOffToggle || disableButton}>
                    {isOff ?
                        <OffIcon /> :
                        <OnIcon />}
                </button>
                <button id={`${type}-options-button`} className='options-button' onClick={toggleOptionsPanel} disabled={disableOptions || disableButton}>
                    <OptionsDots />
                </button>
            </div>
            {labelText && <label>{labelText}</label>}
        </div>
    );
}

export default IOButton;