import { createContext } from 'react';

export const ClientContext = createContext();
export const SidebarContext = createContext({
    isWaitingRoom: true,
    isHost: false,
    sessionHost: undefined,
    openModal: () => {},
    spotlightCards: [],
    setSpotlightCards: () => {},
});
export const ChatContext = createContext({
    inChat: false,
    setInChat: () => {},
    totalUnread: 0
});
export const CrisisContext = createContext();