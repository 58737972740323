import { useEffect, useRef } from 'react';

import './Modal.css';

const Modal = ({ className, isModalOpen, setModalOpen, canClose, children }) => {
    const modalRef = useRef(null);

    // Sync modal display state with value of isModalOpen
    useEffect(() => {
        let modalElement = modalRef.current;
        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [isModalOpen, modalRef]);

    // Esc key cancels modal dialogs
    const onCancel = (e) => {
        if (canClose) {
            e.preventDefault();
            if (canClose()) {
                setModalOpen(false);
            }
        } 
    }

    return (
        <dialog className={`video-conferencing-modal ${className}`} ref={modalRef} onCancel={onCancel}>
            {children}
        </dialog>
    );
}

export default Modal;