import { useState, useEffect, useContext } from 'react';
import { VideoQuality } from '@zoom/videosdk';
import { ClientContext } from '../contexts/zoom-context';
import PaginationControls from './PaginationControls';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { logError } from '../util/util';
import { isCurrentUser } from '../util/meetingHelpers';
import {
    setVideoDivNotPlaying,
    setVideoDivPlaying,
    unassignVideoDiv,
    assignVideoDiv,
    findVideoDivByUserId,
    getVideoDivArray,
    getUserIdFromDiv
} from '../util/videoGalleryHelpers';

import './VideoGallery.css';

const MAX_PAGE_SIZE = 4;

const VideoGallery = (props) => {
    const client = useContext(ClientContext);
    const stream = client.getMediaStream();

    const [userIdRenderOrder, setUserIdRenderOrder] = useState([]);
    const [pageSize, setPageSize] = useState(1);
    const [numPages, setNumPages] = useState(1);
    const [currPage, setCurrPage] = useState(0);

    const startRenderingVideo = async (userId) => {
        const videoDiv = findVideoDivByUserId(userId);
        if (videoDiv) {
            const userVideo = await stream.attachVideo(userId, VideoQuality.Video_360P);
            setVideoDivPlaying(videoDiv);
            videoDiv.appendChild(userVideo);
        } else {
            console.log('video div for user:', userId, 'not on-screen');
        }
    }

    const stopRenderingVideo = async (userId) => {
        const videoDiv = findVideoDivByUserId(userId);
        if (videoDiv) {
            const elements = await stream.detachVideo(userId);
            if (Array.isArray(elements)) {
                for (const videoPlayer of elements) {
                    setVideoDivNotPlaying(videoPlayer.offsetParent);
                    videoPlayer.remove();
                }
            } else if (elements) {
                setVideoDivNotPlaying(elements.offsetParent);
                elements.remove();
            }
        } else {
            console.log('video div for user:', userId, 'not on-screen');
        }
    }

    const rerender = async () => {
        console.log('rerendering...');
        const videoDivArr = getVideoDivArray();

        // unrender
        for (let i = 0; i < videoDivArr.length; i++) {
            const videoDiv = videoDivArr[i];
            const divUserId = getUserIdFromDiv(videoDiv);
            try {
                await stopRenderingVideo(divUserId);
                unassignVideoDiv(videoDiv);
            } catch (error) {
                // openErrorModal(error);
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            }
        }

        // render
        for (let i = 0; i < videoDivArr.length; i++) {
            const videoDiv = videoDivArr[i];
            const divUserId = getUserIdFromDiv(videoDiv);
            const userId = userIdRenderOrder[(currPage * pageSize) + i];
            const user = client.getUser(userId);
            if (userId && !divUserId && user) {
                assignVideoDiv(videoDiv, userId, user.displayName);
                if (user.bVideoOn) {
                    try {
                        await startRenderingVideo(userId);
                    } catch (error) {
                        // openErrorModal(error);
                        console.error(error);
                        logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
                    }
                }
            }
        }
    }

    const nextPage = () => {
        setCurrPage(page => (page + 1) % numPages);
    }

    const prevPage = () => {
        setCurrPage(page => {
            const prevPage = (page - 1) % numPages;
            return (prevPage < 0) ? prevPage + numPages : prevPage;
        });
    }

    useParticipantsChange(client, () => {
        // update the render order
        const listStart = [];
        const listEnd = [];
        for (const user of client.getAllUser()) {
            if (!isCurrentUser(client, user)) {
                user.isHost ? listStart.push(user.userId) : listEnd.push(user.userId);
            }
        }
        let newRenderOrder = [...listStart, client.getCurrentUserInfo().userId, ...listEnd];
        setUserIdRenderOrder(newRenderOrder);

        // update pagination info
        let newUserCount = client.getAllUser().length;
        let newPageSize = Math.min(newUserCount, MAX_PAGE_SIZE);
        let newPageCount = Math.ceil(newUserCount / newPageSize);

        setPageSize(newPageSize);
        setNumPages(newPageCount);
        if (currPage >= newPageCount) {
            setCurrPage(Math.max(newPageCount - 1, 0));
        }
    });

    useEffect(() => {
        rerender();
    }, [userIdRenderOrder, currPage, pageSize]);

    useEffect(() => {
        const onUserUpdated = async (updatedParticipants) => {
            for (const updatedProps of updatedParticipants) {
                if (updatedProps['bVideoOn'] === true) {
                    try {
                        await startRenderingVideo(updatedProps.userId);
                        console.log(`rendering user ${updatedProps.userId} video`);
                    } catch (error) {
                        // openErrorModal(error);
                        console.error(error);
                        logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
                    }
                } else if (updatedProps['bVideoOn'] === false) {
                    try {
                        await stopRenderingVideo(updatedProps.userId);
                        console.log(`unrendering user ${updatedProps.userId} video`);
                    } catch (error) {
                        // openErrorModal(error);
                        console.error(error);
                        logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
                    }
                }
            }
        }
        client.on('user-updated', onUserUpdated);
        return () => client.off('user-updated', onUserUpdated);
    }, []);

    return (
        <div id='video-gallery-container' className='video-gallery-container'>
            <video-player-container class={`default-layout grid-${pageSize}`}>
                {
                    Array.from({ length: pageSize }, (_, index) => {
                        return (
                            <div key={index} id={`video-div-${index}`} className='video-div placeholder' data-playing-video={false} data-user-id={null}>
                                <label id={`name-tag-${index}`} className='name-tag'></label>
                            </div>
                        );
                    })
                }
                <PaginationControls currPage={currPage} numPages={numPages} incrementPage={nextPage} decrementPage={prevPage} />
            </video-player-container>
        </div>
    );
}

export default VideoGallery;