import React, { useState } from 'react';
import './ContentRectangle.css';
import { ref, get, set, remove } from 'firebase/database';
import { database } from '../firebase'; 
import WorkerEditModal from './WorkerEditModal'

const WorkerContentRectangle = ({ name, email, description, matching, id }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [workerData, setWorkerData] = useState({ name, email, description });

    const handleEditButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleWorkerSelect = async (formData) => {
        try {
          // Update data in the state
          setWorkerData(formData); 

          // Update data in Firebase
          const dbRef = ref(database, `workers/${id}`);
          await set(dbRef, formData);  // Using set function to update data in Firebase

          // Close the modal
          setIsModalVisible(false);
      } catch (error) {
          console.error("Error updating data:", error);
      }
    };

    const handleDeleteButtonClick = async () => {
      try {
          const dbRef = ref(database, `workers/${id}`);
          await remove(dbRef);  // Using remove function to delete data from Firebase
          console.log(`Worker ${id} deleted`);
      } catch (error) {
          console.error("Error deleting data:", error);
      }
  };

    return (
      <div className="content-rectangle">
        <h3 className="name">{workerData.name}</h3>
        <h4 className="status"> Email: {workerData.email}</h4>
        <p className="description">{workerData.description}</p>
        <div className="button-container">
          <button className="button" onClick={handleEditButtonClick}>
            Edit
          </button>
          <button className="button" onClick={handleDeleteButtonClick}> {/* Added this button */}
            Delete
          </button>
          <WorkerEditModal 
            isVisible={isModalVisible} 
            onSelect={handleWorkerSelect} 
            onClose={() => setIsModalVisible(false)}
            name={workerData.name} 
            email={workerData.email}
            description={workerData.description} 
          />
        </div>
      </div>
    );
};

export default WorkerContentRectangle;
