// Workers.js
import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import RightSidebar from '../components/RightSidebar';
import './Home.css';
import './Workers.css';
import { database } from '../firebase'; // Import the database from firebase.js
import { ref, onValue, push } from 'firebase/database';
// importing content rectangle from components
import WorkerContentRectangle from '../components/WorkerContentRectangle';
import WorkerCreateModal from '../components/WorkerCreateModal';  // Added import for the new modal component

const Workers = () => {

  const [data, setData] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);  // Added this state

  const handleCreateWorker = async (worker) => {
    try {
      const workersRef = ref(database, 'workers');
      await push(workersRef, worker);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error('Error adding new worker:', error);
    }
  };

  useEffect(() => {
    const dbRef = ref(database, "workers");
    // fetch dbref and wrap it in a try catch block
    // wrap it in a try catch block
    // if error, set data to empty array
    // if no error, set data to transformedData
    try {
      
        onValue(dbRef, (snapshot) => {
        const fetchedData = snapshot.val();
        const transformedData = fetchedData ? Object.entries(fetchedData).map(([id, value]) => ({ ...value, id })) : [];
        setData(transformedData);
       }, {
         onlyOnce: true,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
      setData([]);
    }
  }, [data]);

  return (
    <div className="home">
      <div className="left-sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <TopBar />
        <h3 className="page-headline" style={{ marginLeft: 50 }}> Workers 
        <button className="button" style={{ marginLeft: 20 }} onClick={() => setIsCreateModalVisible(true)}>  {/* Added this button */}
          Add a Worker
        </button>
        </h3>
        {data.map((item, index) => (
          <WorkerContentRectangle
            key={index}
            id={item.id}
            name={item.name}
            email={item.email}
            description={item.description}
            matching={item.matching}
          />
        ))}
        <WorkerCreateModal  // Added this modal
        isVisible={isCreateModalVisible} 
        onCreate={handleCreateWorker} 
        onClose={() => setIsCreateModalVisible(false)}
      />
      </div>
      <div className="right-sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default Workers;
