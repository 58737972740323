import { useEffect } from 'react';
import './Toast.css';

const Toast = ({ message, isShown, onClose }) => {

    useEffect(() => {
        if (isShown) {
            const timeoutId = setTimeout(() => {
                onClose();
            }, 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [isShown]);

    return (
        isShown && (
            <div className='toast'>
                {message}
            </div>
        )
    );
}

export default Toast;