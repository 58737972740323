import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { ClientContext, ChatContext } from '../../../contexts/zoom-context';
import ChatMessage from './ChatMessage';
import ChatSidebar from './ChatSidebar';
import { CaretLeft, CaretRight, SendArrowRight } from '../../common/Icons';

import './ChatPage.css';

const ChatPage = ({ className }) => {
    const client = useContext(ClientContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [messageDraft, setMessageDraft] = useState('');
    const messagesEndRef = useRef(null);
    const {
        isHost,
        chatUser,
        records,
        sendMessage,
        isSenderOrReceiver,
        isAnnouncement
    } = useContext(ChatContext);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
    }, [records, chatUser]);

    const onChatInput = (e) => {
        setMessageDraft(e.target.value);
    }

    const onChatKeyDown = (e) => {
        if (e.key === 'Enter') send();
    };

    const send = useCallback(() => {
        console.log('send');
        if (!chatUser) {
            console.error('Please select a user to send a message to');
            return;
        }
        const message = messageDraft.trim();
        if (message) {
            console.log(`sendMessage::"${message}" to`, chatUser);
            sendMessage(message);
            setMessageDraft('');
        }
    }, [sendMessage, chatUser, messageDraft]);

    const messageBelongsInCurrentThread = useCallback((chatMessage) => {
        const currentUser = client.getCurrentUserInfo();
        if ((isSenderOrReceiver(chatMessage, currentUser?.userId) && isSenderOrReceiver(chatMessage, chatUser?.userId)) ||
            isAnnouncement(chatMessage)) {
            return true;
        }
        return false;
    }, [client, chatUser]);

    return (
        <div className={`${className} chat ${(isHost && sidebarOpen) ? 'sidebar-open' : undefined}`}>
            {isHost && <ChatSidebar isOpen={sidebarOpen} />}
            <div className='chat-window'>
                <div className='chat-header'>
                    {isHost && <button className='open-close-toggle' onClick={() => setSidebarOpen(prev => !prev)}>
                        {sidebarOpen ? <CaretLeft /> : <CaretRight />}
                    </button>}
                    <div className='header-text'>
                        {chatUser ? chatUser.displayName : 'No recipient selected'}
                    </div>
                </div>
                <div className='messages-container'>
                    <div className='message-thread'>
                        {records
                            .filter((chatMessage) => messageBelongsInCurrentThread(chatMessage))
                            .map((chatMessage, index) => {
                                return (
                                    <ChatMessage
                                        key={chatMessage.timestamp}
                                        sentByCurrentUser={chatMessage.sender.userId === client.getCurrentUserInfo().userId}
                                        isAnnouncement={isAnnouncement(chatMessage)}
                                        senderName={chatMessage.sender.name}
                                        timestamp={new Date(chatMessage.timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                                        messageBody={chatMessage.message}
                                    />
                                );
                            })}
                        <div ref={messagesEndRef}></div>
                    </div>
                    <div className='message-input-field'>
                        <input
                            id='message-input'
                            type='text'
                            placeholder='Send message...'
                            value={messageDraft}
                            onChange={onChatInput}
                            onKeyDown={onChatKeyDown}
                        />
                        <button id='send-btn' onClick={send} className='send-btn'><SendArrowRight /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatPage;