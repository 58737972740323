import React, { useEffect, useRef } from 'react';
import styles from './PeerSupportForm.css';

export default function PeerSupportForm() {
    const formRef = useRef(null);

    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;

        // Append script to the body
        document.body.appendChild(script);

        // Set up Typeform container
        formRef.current.innerHTML = `
          <div data-tf-widget="b6ahCw00" 
               data-tf-opacity="100" 
               data-tf-inline-on-mobile 
               data-tf-iframe-props="title=Request Form for Peer Support" 
               data-tf-transitive-search-params 
               data-tf-auto-focus 
               data-tf-medium="snippet" 
               data-tf-full-screen>
          </div>`;

        return () => {
            // Cleanup script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div ref={formRef} className={styles.formContainer}></div>
    );
}
