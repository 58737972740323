import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

const ProtectedRoute = () => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Outlet />;
  } else {
    return <Navigate to="/signin" replace />;
  }
};

export default ProtectedRoute;
