import { useState, useEffect, useCallback } from "react";

// TODO: implement hook
export function useScreenShare(client, stream, shareRef) {
    const [isStartedShare, setIsStartedShare] = useState(false);
    const [isReceiveSharing, setIsReceiveSharing] = useState(false);

    const startScreenSharing = useCallback(async () => {
        if (shareRef.current) {
            await stream?.startShareScreen(shareRef.current);
            setIsStartedShare(true);
        }
    }, [shareRef, stream]);

    const stopScreenSharing = useCallback(async () => {
        await stream?.stopShareScreen();
        setIsStartedShare(false);
    }, [stream]);

    const startWatchingScreenShare = useCallback(async (userId) => {
        if (shareRef.current) {
            await stream?.startShareView(shareRef, userId);
            setIsReceiveSharing(true);
        }
    }, [shareRef, stream]);

    const stopWatchingScreenShare = useCallback(async (userId) => {
        await stream?.stopShareView();
        setIsReceiveSharing(false);
    }, [stream]);

    useEffect(() => {
        // client.on('active-share-change', () => {});
        // client.on('user-updated', () => {});
        // client.on('peer-share-state-change', () => {});
        // client.on('peer-video-state-change', () => {});
        // client.on('passively-stop-share', () => {});
        // return () => {
        //     client.off('active-share-change', () => {});
        //     client.off('user-updated', () => {});
        //     client.off('peer-share-state-change', () => {});
        //     client.off('peer-video-state-change', () => {});
        //     client.off('passively-stop-share', () => {});
        // }
    }, []);

    return {
        isStartedShare,
        isReceiveSharing,
        startScreenSharing,
        stopScreenSharing
    };
}