import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import RightSidebar from '../components/RightSidebar';
import { app, database, functions, httpsCallable } from '../firebase'; // Import the database from firebase.js
import Video from 'twilio-video';
import { useAuth } from '../contexts/AuthContext';
import { ref, onValue, set, push } from 'firebase/database';
import './Programs.css';

const Programs = () => {
  const [hyphenProgramName, setHyphenProgramName] = useState('Select a program');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programList, setProgramList] = useState([]);
  const [brodMessage, setBrodMessage] = useState(null);
  const [programData, setProgramData] = useState({});
  const { currentUser } = useAuth();

  // handle submit function to update the firebase realtime database with the changes made by the user on the selected program
  const handleSubmit = () => {
     // update firebase realtime database entity
     // wrap it in a try catch block
     if (selectedProgram) {
      try {
        // update firebase ref with the new datas
        if (selectedProgram == 'new program, pending registration') {
          const dbRef = ref(database, `programs/${programData.name}`);
          set(dbRef, programData);
          // const namesRef = ref(database, `programs/names`);
          // const newProgramList = programList.push(programData.name);
          // setProgramList(newProgramList);
          // console.log(programList);
          // set(namesRef, programList);
          document.getElementById("notif_message").hidden = true;
          document.getElementById("EnterProgramName").hidden = true;
          alert('The new program will be reflected in the next 6 hours. Thank you!');
        } else {
          const dbRef = ref(database, `programs/${selectedProgram}`);
          set(dbRef, programData);
          document.getElementById("notif_message").hidden = true;
          alert('Your changes have been successfully updated. Thank you!');
        }
        } catch(error) {
          console.log(error);
          alert("There was an error updating the database. We apologize for the inconvenicence. Please contact cj@peersupport.io for assistance.");
        }
     } else {
        alert("Please select a program first.");
     }
  }

  // function to handle notification message changes
  const handleNotifMessageChange = () => {
      // turn off hidden property of the notif_message div
      // turn it to false only if selectedProgram is not null
      if (selectedProgram) {
        document.getElementById("notif_message").hidden = false;
      } else {
        alert("Please select a program first.");
      }
  }

  const showBrodcastBar = () => {
      // turn off hidden property of the notif_message div
      if (selectedProgram) {
        document.getElementById("brodBar").hidden = false;
      } else {
        alert("Please select a program first.");
      }
  }

  // function to handle broadcast message to all participants
  const handleBrodcastMessage = () => {
      // create a new entity in the firebase realtime database under "broadcasts"
      // wrap it in a try catch block
      try {
      const dbRef = ref(database, `broadcasts`);
      // create an array containing the program name and the email address of the authetnicated user
      // retreive the email address of the authenticated user from the firebase authentication
      const broadcastData = {
        program: selectedProgram, 
        email: currentUser.email, 
        message: brodMessage,
        status: "queued"
      }; 
      // push the new data to the firebase ref
      push(dbRef, broadcastData);
      document.getElementById("brodBar").hidden = true;
      alert('Your request has been successfully recorded. Thank you!');
    } catch(error) {
      console.log(error);
      alert("There was an error updating the database. We apologize for the inconvenicence. Please contact cj@peersupport.io for assistance.");
    }
  }


  // handleExportRegistrations functions to email the current registrations for the selected program to the admin
  const handleExportRegistrations = () => {
    if(selectedProgram) {
      try { 
        const dbRef = ref(database, `exports/programs`);
        const status = "queued";
        // create an array containing the program name and the email address of the authetnicated user
        // retreive the email address of the authenticated user from the firebase authentication
        const exportData = {
          program: selectedProgram, 
          email: currentUser.email, 
          status: status
        };
        // update firebase ref with the new data
        push(dbRef, exportData);
        alert('Your request has been successfully submitted. You will receive an email with the requested information shortly. Thank you!');
      } catch {
        alert("There was an error exporting the registrations. We apologize for the inconvenicence. Please contact cj@peersupport.io");
      }
    } else {
      alert("Please select a program first.");
    }
  }

  const createProgram = () => {
      setSelectedProgram('new program, pending registration');
      document.getElementById("EnterProgramName").hidden = false;
      setProgramData({
        name: 'Enter program name',
        cadence: 'Enter cadence',
        notification: 'Enter notification settings',
        acceptance: 'Enter acceptance settings',
        roomlink: 'Enter virtual room address',
        participants: [],
        notif_message: 'Hello, Please replace this text with the notification message you would like to send to users prior to sessions. The notification delivery will be based on the notification settings (which you can update above). This email will include Jackys signature.'
      })
  }

  useEffect(() => {
    // Fetching the program names
    const dbRef = ref(database, "programs/names");
    onValue(dbRef, (snapshot) => {
      const fetchedData = snapshot.val();
      const transformedData = fetchedData ? Object.values(fetchedData) : [];
      setProgramList(transformedData);
      console.log(programList.length);
    }, { onlyOnce: true });
  }, []);

  useEffect(() => {
    // Fetching the selected program's data
    if (selectedProgram && selectedProgram !== 'new program, pending registration') {
      if (selectedProgram == 'The Meeting Spot Support Group') {

        const dbRef = ref(database, `programs/Meeting Spot`);
        onValue(dbRef, (snapshot) => {
          setProgramData(snapshot.val());
          setSelectedProgram(snapshot.val().name);
        }, { onlyOnce: true });

      } else if (selectedProgram == 'Lived Experience Advocates for Mental Health and Addictions (LEAMHA)') {

        const dbRef = ref(database, `programs/LEAMHA (Lived Experienced Advocates for Mental & Addictions)`);
        onValue(dbRef, (snapshot) => {
          setProgramData(snapshot.val());
          setSelectedProgram(snapshot.val().name);
        }, { onlyOnce: true });

      } else {
        const dbRef = ref(database, `programs/${selectedProgram}`);
        onValue(dbRef, (snapshot) => {
          setProgramData(snapshot.val());
          setSelectedProgram(snapshot.val().name);
        }, { onlyOnce: true });
      }
    }
    console.log(selectedProgram);
  }, [selectedProgram]);

  // use effect to update the roomlink component with concatenation as the user types the program name. https://krasman.peersupport.io/join/{program name}
  useEffect(() => {
    if (programData.name) {
      // automatically add '_' if the user hits space bar in the program name
      const programName = programData.name.split(' ').join('_');
      setHyphenProgramName(programData.name.split(' ').join('-'));
      setProgramData({
        ...programData,
        roomlink: `https://krasman.peersupport.io/join/${programName}`
      });
    }
  }, [programData.name]); 

  const startSession = () => {
    if (selectedProgram) {
      window.location.href = `https://ubc.zoom.us/j/7979766038?pwd=eWtPeHIxRkFVckNmeGRlVmJ6MTRPdz09`;
    } else {
      alert("Please select a program first.");
    }
  };

  return (
    <div className="home">
      <div className="left-sidebar">
        <Sidebar />
      </div>
      <div className="content">
      <TopBar />
      <div className="programs-content-container"> 
        <div className="program-customization">
            <h2 className="page-headline">{programData.name || ''}</h2>
            <h3>Registration Form: https://krasmancentre.peersupport.io/program-form/{hyphenProgramName}</h3>
            <div hidden id="EnterProgramName" className="customization-field">
              <input 
                type="text"
                value={programData.name || ''}
                onChange={(e) => setProgramData({ ...programData, name: e.target.value })}
                className="custom-input"
              />
            </div>
            <div className="customization-field">
              <h3>Description</h3>
              <textarea 
                  ows="5" 
                value={programData.description || ''} 
                onChange={(e) => setProgramData({ ...programData, cadence: e.target.value })}
                className="custom-input"
              />
            </div>
            <div className="customization-field">
              <h3>Frequency</h3>
              <input 
                type="text" 
                value={programData.cadence || ''} 
                onChange={(e) => setProgramData({ ...programData, cadence: e.target.value })}
                className="custom-input"
              />
            </div>
            <div className="customization-field">
              <h3>Notification Settings</h3>
              <input 
                type="text" 
                value={programData.notification || ''} 
                onChange={(e) => setProgramData({ ...programData, notification: e.target.value })}
                className="custom-input"
              />
            </div>
            <div className="customization-field">
              <h3>Accepting Participants</h3>
              <input 
                type="text" 
                value={programData.acceptance || ''} 
                onChange={(e) => setProgramData({ ...programData, acceptance: e.target.value })}
                className="custom-input"
              />
            </div>
            <div className="customization-field">
              <h3>Virtual room address</h3>
              <input 
                type="text" 
                value={programData.roomlink || ''} 
                onChange={(e) => setProgramData({ ...programData, roomlink: e.target.value })}
                className="custom-input"
              />
              <button
                className="programsSubmitButton"
                variant="contained"
                onClick={startSession}>
                Start Meeting
              </button>   
            </div>
            <button
              className="programsSubmitButton"
              variant="contained"
              onClick={handleSubmit}>
              Submit changes
            </button>
            <button
              className="programsSubmitButton"
              variant="contained"
              onClick={handleExportRegistrations}>
              Retrieve current registrations
            </button>
            <button
              className="programsSubmitButton"
              variant="contained"
              onClick={handleNotifMessageChange}>
              Change Notification Messages
            </button>
            <button
              className="programsSubmitButton"
              variant="contained"
              onClick={showBrodcastBar}>
              Send broadcast message to all participants
            </button>
            <div hidden id="notif_message">
              <h3>Notification Message</h3>
                <textarea 
                  rows="5" 
                  value={programData.notif_message || ''} 
                  onChange={(e) => setProgramData({ ...programData, notif_message: e.target.value })}
                  className="custom-input"
                ></textarea>
                <button
                  className="programsSubmitButton"
                  variant="contained"
                  onClick={handleSubmit}>
                  Update notification message
                </button>
            </div>
            <div hidden id="brodBar">
              <h3>Send a message to all participants</h3>
              <textarea
                  rows="5"  // This determines the initial visible number of lines in textarea. Adjust as needed.
                  onChange={(e) => setBrodMessage(e.target.value)}
                  className="custom-input"
              ></textarea>
                <button
                  className="programsSubmitButton"
                  variant="contained"
                  onClick={handleBrodcastMessage}>
                  Send message
                </button>
            </div>
          </div>

          <div className="program-selection">
            <h2>Select Program</h2>
              <div className="radio-col">
              {programList.map(program => (
                <label key={program} className="custom-radio-programs">
                  <input
                    type="radio"
                    name="program"
                    value={program}
                    className="radio-input-programs"
                    checked={selectedProgram === program}
                    onChange={() => setSelectedProgram(program)}
                  />
                  <div className="radio-display-programs"></div>
                  {program}
                </label>
              ))}
              <button
                  className="programsSubmitButton"
                  variant="contained"
                  onClick={createProgram}>
                  Create program +
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="right-sidebar">
        <RightSidebar />
      </div>
    </div>
  );
};

export default Programs;
