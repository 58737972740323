import { Headers } from './Sidebar';
import AgendaPage from './AgendaPage';
import ParticipantsPage from './ParticipantsPage';
import AbsenteesPage from './AbsenteesPage';
import BreakoutRoomsPage from './BreakoutRoomsPage';
import ScreenSharePage from './ScreenSharePage';
import ChatPage from './Chat/ChatPage';
import SpotlightPage from './Spotlight/SpotlightPage';

import './SidebarContentPage.css';

const contentPageClassName = 'vc-sidebar-content-page';

const SidebarContentPage = ({ header }) => {
    if (header === Headers.AGENDA) {
        return <AgendaPage className={contentPageClassName} />
    } else if (header === Headers.PARTICPANTS) {
        return <ParticipantsPage className={contentPageClassName} />
    } else if (header === Headers.ABSENTEES) {
        return <AbsenteesPage className={contentPageClassName} />
    } else if (header === Headers.BREAKOUT_ROOMS) {
        return <BreakoutRoomsPage className={contentPageClassName} />
    } else if (header === Headers.SCREENSHARE) {
        return <ScreenSharePage className={contentPageClassName} />
    } else if (header === Headers.CHAT) {
        return <ChatPage className={contentPageClassName} />
    } else if (header === Headers.SPOTLIGHT) {
        return <SpotlightPage className={contentPageClassName} />
    } else {
        return <div className={`${contentPageClassName} blank`}>There's nothing here</div>
    }
}

export default SidebarContentPage;