// TopBar.js
import React, { useEffect, useState } from 'react';
import './TopBar.css';
import { useAuth } from '../contexts/AuthContext';

const TopBar = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState(null);

  // if the user is not logged in, set name as ""
  useEffect(() => {
    if (!currentUser) {
      setName("");
    } else if (currentUser.email == "j.odish@krasmancentre.com") {
      setName("Jacky");
    } else if (currentUser.email == "cj@peersupport.io") {
      setName("Chirag");
    } else if (currentUser.email == "s.dobson@krasmancentre.com") {
      setName("Susan");
    } else if (currentUser.email == "a.gooding@krasmancentre.com") {
      setName("Andrea");
    } else if (currentUser.email == "a.september@krasmancentre.com") {
      setName("Aysa");
    } else if (currentUser.email == "tomurban3@gmail.com") {
      setName("Tom");
    } else if (currentUser.email == "s.nicklin@krasmancentre.com") {
      setName("Sara");
    } else if (currentUser.email == "w.braaten@krasmancentre.com") {
      setName("Wren");
    } else if (currentUser.email == "crichard@yukonu.ca") {
      setName("Clement");
    } else if (currentUser.email == "jacqueline@sophiarecovery.com") {
      setName("Jacqueline");
    } else if (currentUser.email == "natasha@allinagency.ca") {
      setName("Natasha");
    } else if (currentUser.email == "alexander@ondeck-ai.com") {
      setName("Honey");
    } else {
      setName("User not recognized");
    }
  }, [currentUser]);

  return (
    <div className="top-bar">
      <span>Welcome {name}!</span>
      <div className="logo-container">
        <img src="krasman.png" alt="Logo" className="logo" />
      </div>
    </div>
  );
};

export default TopBar;
