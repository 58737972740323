import { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { ClientContext } from '../contexts/zoom-context';
import MeetingRoom from './MeetingRoom';

const ProtectedMeetingRoom = () => {
    const client = useContext(ClientContext);
    const location = useLocation();
    if (!location.state || !client || !client.getCurrentUserInfo()) {
        // TODO: navigate to an error page?
        return <Navigate to='/' replace />;
    } else {
        return <MeetingRoom />;
    }
}

export default ProtectedMeetingRoom;