import * as Icons from './common/Icons';

import './PaginationControls.css';

const PaginationControls = ({ currPage, numPages, incrementPage, decrementPage }) => {
    if (numPages <= 1) {
        return <></>;
    }
    return (
        <div className='pagination-controls'>
            <button onClick={decrementPage} className='prev'><Icons.CaretLeft /></button>
            <div className='dots'>
                {Array.from({ length: numPages }, (_, index) => {
                    const highlighted = (index === currPage);
                    return <div key={index} className={`circle-container ${highlighted ? 'highlighted' : undefined}`}><Icons.Circle /></div>
                })}
            </div>
            <button onClick={incrementPage} className='next'><Icons.CaretRight /></button>
        </div>
    );
}

export default PaginationControls;