import styles from './PeerSupportForm.css';

import React, { useState, useEffect } from 'react';
import { ref, set, get, update, remove } from 'firebase/database';
import { database } from '../firebase';

const FollowupForm = () => {
  const [status, setStatus] = useState("");
  const [psworker, setPsworker] = useState("");
  const [name, setName] = useState("");

  const ticketId = new URLSearchParams(window.location.search).get('matchId');

  useEffect(() => {
    if (ticketId) {
      const ticketRef = ref(database, `followup/${ticketId}`);
      get(ticketRef)
        .then(snapshot => {
          const data = snapshot.val();
          if (data) {
            setPsworker(data.psworker || "");
            setName(data.name || "");
          }
        })
        .catch(error => {
          console.error("Error reading data from Firebase:", error);
        });
    }
  }, [ticketId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ticketId) {
      const ticketRef = ref(database, `followup/${ticketId}`);
      
      // Use update() instead of set()
      await update(ticketRef, { status });
      let newRefPath;
      if (status == "Care completed") {
        newRefPath = `completed/${ticketId}`;
      } else {
        newRefPath = `ongoing/${ticketId}`;
      }

      const newRef = ref(database, newRefPath);

        const snapshot = await get(ticketRef);
        const data = snapshot.val();
        if (data) {
        await set(newRef, data);
        await remove(ticketRef);
        }
      
      alert("Thank you for submitting the survey!");
      // Redirect or show a confirmation message
    } else {
      alert("Invalid ticket ID");
    }
  };  

  return (
    <div className="screenContainer">
      <div className="formContainer">
        <div className="form-logo-container">
          <img src="https://drive.google.com/uc?export=view&id=1-2y8GZt4aUcNBriJN26GefwhSHzBYQRp" alt="Logo" className="formlogo" />
        </div>
        <button className="backButton" onClick={() => {
          window.open("https://krasmancentre.com", "_blank");
        }}>
          Back to Krasman Centre Homepage
        </button>

        <p className="formHeader">
          Peer Support Followup
        </p>

        {/* Added Message */}
        <p style={{ fontSize: '18px', textAlign: 'left' }}>
          Hi {psworker},<br/>
          <br />
          Could you please let me know how things are going with {name}?<br/>
          Thanks so much,<br/>
          <br />
          Jacky
        </p>

        <label className="custom-radio">
        <input
          type="radio"
          value="Need to reach out"
          checked={status === "Need to reach out"}
          onChange={(e) => setStatus(e.target.value)}
          className="radio-input"
          id="reach-out"
        />
         <div className="radio-display"></div>
        Need to reach out
      </label>
      <label className="custom-radio">
        <input
          type="radio"
          value="Yet to meet for the first time"
          checked={status === "Yet to meet for the first time"}
          onChange={(e) => setStatus(e.target.value)}
          className="radio-input"
          id="reach-out"
        />
        <div className="radio-display"></div>
        Yet to meet for the first time
      </label>
      <label className="custom-radio">
        <input
          type="radio"
          value="Currently providing care"
          checked={status === "Currently providing care"}
          onChange={(e) => setStatus(e.target.value)}
          className="radio-input"
          id="reach-out"
        />
        <div className="radio-display"></div>
        Currently providing care
      </label>
      <label className="custom-radio">
        <input
          type="radio"
          value="Care completed"
          checked={status === "Care completed"}
          onChange={(e) => setStatus(e.target.value)}
          className="radio-input"
          id="reach-out"
        />
        <div className="radio-display"></div>
        Care completed
      </label>
      <button
          className="submitButton"
          variant="contained"
          onClick={handleSubmit}
        >
          Submit Request
        </button>
      </div>
    </div>
  );
};

export default FollowupForm;
