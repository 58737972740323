import Separator from '../common/Separator';

import './AgendaPage.css';

// TODO: fetch real agenda from database
const stubAgenda = {
    title: 'Skills For Emotional Resilience - Week 2',
    items: [
        {
            topic: 'Icebreaker',
            startTime: '9:00',
            endTime: '9:10'
        },
        {
            topic: 'Overview',
            startTime: '9:10',
            endTime: '9:15'
        }
    ]
}

const fillerItems = Array.from({ length: 15 }).map((_, index) => {
    return {
        topic: `Topic #${index}`,
        startTime: '12:00',
        endTime: '12:00'
    }
});

const Item = ({ topic, startTime, endTime }) => {
    return (
        <li>
            <div className='time'>{`${startTime} - ${endTime}`}</div>
            <div className='topic'>{topic}</div>
        </li>
    );
}

const AgendaPage = ({ className }) => {
    const agendaItems = [...stubAgenda.items, ...fillerItems];

    return (
        <div className={`${className} agenda`}>
            <h1>{stubAgenda.title}</h1>
            <Separator />
            <ul>
                {agendaItems.map((item, index) => 
                    <Item key={index} topic={item.topic} startTime={item.startTime} endTime={item.endTime} />
                )}
            </ul>
        </div>
    );
}

export default AgendaPage;