import { Checkmark } from '../../common/Icons';

import './SpotlightTopicToggle.css';

const SpotlightTopicToggle = ({ onClick, isActive, Icon, children }) => {
    return (
        <button className={`spotlight-topic-toggle ${isActive ? 'active' : 'inactive'}`} onClick={onClick}>
            <div className='icon-container'>
                <Icon />
            </div>
            {children}
            {isActive && <Checkmark />}
        </button>
    );
}

export default SpotlightTopicToggle;