// CenterBoxes.js
import React, { useEffect } from 'react';
import './CenterBoxes.css';

const CenterBoxes = ({ selectedLink, onLinkClick }) => {
  const handleLinkClick = (link, event) => {
    event.preventDefault();
    onLinkClick(link);
  };
  
  useEffect(() => {
    // console.log(selectedLink);
  }, [selectedLink]);

  return (
    <div className="center-boxes">
      <a href="#backlog" style={selectedLink === 'backlog' ? selectedLinkStyle : linkStyle} onClick={(e) => handleLinkClick('backlog', e)}>
        Backlog Requests
      </a>
      <a href="#ongoing" style={selectedLink === 'ongoing' ? selectedLinkStyle : linkStyle} onClick={(e) => handleLinkClick('ongoing', e)}>
        Ongoing Engagements
      </a>
      <a href="#completed" style={selectedLink === 'completed' ? selectedLinkStyle : linkStyle} onClick={(e) => handleLinkClick('completed', e)}>
        Completed Engagements
      </a>
    </div>
  );
};

export default CenterBoxes;

const linkStyle = {
  color: 'var(--ui-dark-purple, #4E2375)',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  textDecoration: 'none',
  height: '23px',
  flexShrink: 0,
  marginRight: '25px',
};

const selectedLinkStyle = {
  ...linkStyle,
  fontWeight: 700,
};
