import { useState, useEffect, useContext } from 'react';
import { VideoQuality } from '@zoom/videosdk';
import classNames from 'classnames';
import { ClientContext } from '../contexts/zoom-context';
import { useParticipantsChange } from '../hooks/useParticipantsChange';
import { isCurrentUser } from '../util/meetingHelpers';
import { logError } from '../util/util';
import {
    setVideoDivNotPlaying,
    setVideoDivPlaying,
    unassignVideoDiv,
    assignVideoDiv,
    findVideoDivByUserId,
    getUserIdFromDiv
} from '../util/videoGalleryHelpers';
import './VideoSingle.css';

const VideoSingle = (props) => {
    const client = useContext(ClientContext);
    const stream = client.getMediaStream();
    const [activeUserId, setActiveUserId] = useState();

    // Copy of VideoGallery function
    const startRenderingVideo = async (userId) => {
        const videoDiv = findVideoDivByUserId(userId);
        if (videoDiv) {
            const userVideo = await stream.attachVideo(userId, VideoQuality.Video_360P);
            setVideoDivPlaying(videoDiv);
            videoDiv.appendChild(userVideo);
        } else {
            console.log('video div for user:', userId, 'not on-screen');
        }
    }

    // Copy of VideoGallery function
    const stopRenderingVideo = async (userId) => {
        const videoDiv = findVideoDivByUserId(userId);
        if (videoDiv) {
            const elements = await stream.detachVideo(userId);
            if (Array.isArray(elements)) {
                for (const videoPlayer of elements) {
                    setVideoDivNotPlaying(videoPlayer.offsetParent);
                    videoPlayer.remove();
                }
            } else if (elements) {
                setVideoDivNotPlaying(elements.offsetParent);
                elements.remove();
            }
        } else {
            console.log('video div for user:', userId, 'not on-screen');
        }
    }

    const changeActiveUser = async (userId) => {
        try {
            const user = client.getUser(userId);
            if (!user) {
                throw Error('User not found');
            };
            if (isCurrentUser(client, user)) {
                return;
            }
            console.log('Active User:', user.displayName, `(${user.userId})`);
            const activeVideoDiv = document.querySelector('#video-div-active');
            const divUserId = getUserIdFromDiv(activeVideoDiv);
            await stopRenderingVideo(divUserId);
            unassignVideoDiv(activeVideoDiv);
            assignVideoDiv(activeVideoDiv, userId, user.displayName);
            await startRenderingVideo(userId);
        } catch (error) {
            console.error(error);
            logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
        }
    }

    // Copy from VideoGallery
    const onUserUpdated = async (updatedParticipants) => {
        for (const updatedProps of updatedParticipants) {
            if (updatedProps['bVideoOn'] === true) {
                try {
                    await startRenderingVideo(updatedProps.userId);
                    console.log(`rendering user ${updatedProps.userId} video`);
                } catch (error) {
                    // openErrorModal(error);
                    console.error(error);
                    logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
                }
            } else if (updatedProps['bVideoOn'] === false) {
                try {
                    await stopRenderingVideo(updatedProps.userId);
                    console.log(`unrendering user ${updatedProps.userId} video`);
                } catch (error) {
                    // openErrorModal(error);
                    console.error(error);
                    logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
                }
            }
        }
    }

    const onVideoActiveChange = async ({ state, userId }) => {
        if (state === 'Active') {
            return changeActiveUser(userId);
        }
    }

    useParticipantsChange(client, async () => {
        if (!client.getUser(activeUserId)) {
            let newActiveUserId;
            for (const user of client.getAllUser()) {
                if (!isCurrentUser(client, user)) {
                    newActiveUserId = user.userId;
                    break;
                }
            }
            setActiveUserId(newActiveUserId);
        }
    });

    useEffect(() => {
        changeActiveUser(activeUserId);
    }, [activeUserId]);

    useEffect(() => {
        client.on('user-updated', onUserUpdated);
        client.on('video-active-change', onVideoActiveChange);
        return () => {
            client.off('user-updated', onUserUpdated);
            client.off('video-active-change', onVideoActiveChange);
        }
    });

    return (
        <div className={classNames('video-single-container', { 'self-view-only': !activeUserId })}>
            <div className='col'>
                <video-player-container class-name='self-view-vpc'>
                    <div id={`video-div-self`} className='video-div placeholder' data-playing-video={false} data-user-id={client.getCurrentUserInfo()?.userId}>
                        <label id={`name-tag-self`} className='name-tag'>{client.getCurrentUserInfo()?.displayName}</label>
                    </div>
                </video-player-container>
            </div>
            {activeUserId &&
                <div className='col'>
                    <video-player-container class-name='active-view-vpc'>
                        <div id={`video-div-active`} className='video-div placeholder' data-playing-video={false} data-user-id={null}>
                            <label id={`name-tag-active`} className='name-tag'>Nobody</label>
                        </div>
                    </video-player-container>
                </div>
            }
        </div>
    );
}

export default VideoSingle;