import { useState, useEffect } from 'react';
import './TimeDisplay.css';

function msToHMS(duration) {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor(duration / (1000 * 60 * 60));
    return { hours, minutes, seconds };
}

function getTimeString(hours, minutes, seconds) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function getLongTimeString(hours, minutes, seconds) {
    return `${hours} hours ${minutes} minutes ${seconds} seconds`;
}

const TimeSection = ({ value, label }) => {
    return (
        <div className='time-section'>
            <h1>{value}</h1>
            <h2>{label}</h2>
        </div>
    );
}

const TimeDisplay = ({ isPastTarget, setIsPastTarget, targetDate, isWaitingRoom, sendTimeStringToParent }) => {
    const [hoursLeft, setHoursLeft] = useState(0);
    const [minutesLeft, setMinutesLeft] = useState(0);
    const [secondsLeft, setSecondsLeft] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentDate = new Date();
            const duration = Math.abs(targetDate - currentDate);
            const { hours, minutes, seconds } = msToHMS(duration);
            
            if (hours !== hoursLeft) setHoursLeft(hours);
            if (minutes !== minutesLeft) setMinutesLeft(minutes);
            if (seconds !== secondsLeft) setSecondsLeft(seconds);
            if (isPastTarget !== (currentDate > targetDate)) setIsPastTarget(currentDate > targetDate);
            if (sendTimeStringToParent) sendTimeStringToParent(getLongTimeString(hours, minutes, seconds));
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    });

    if (isWaitingRoom) {
        return (
            <div className={`time-display ${isPastTarget ? 'countpast' : undefined}`}>
                <TimeSection value={hoursLeft} label='Hours' />
                <TimeSection value={String(minutesLeft).padStart(2, '0')} label='Minutes' />
                <TimeSection value={String(secondsLeft).padStart(2, '0')} label='Seconds' />
            </div>
        );
    } else {
        return (
            <div className={`time-display ${isPastTarget ? 'countpast' : undefined}`}>
                <label>{`Time ${isPastTarget ? 'Past' : 'Left in'} Meeting`}</label>
                <h1>{getTimeString(hoursLeft, minutesLeft, secondsLeft)}</h1>
            </div>
        );
    }
}

export default TimeDisplay;