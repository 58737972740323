import { useState } from 'react';
import classNames from 'classnames';
import * as Icons from '../../common/Icons';
import SpotlightTopicIcon from './SpotlightTopicIcon';
import SpotlightCardInfoTable from './SpotlightCardInfoTable';

import './SpotlightCard.css';

const SpotlightCard = ({ card, onClick, toggleSave, isExpanded }) => {
    const [showCopiedMsg, setShowCopyMsg] = useState(false);
    const {
        id,
        distance,
        title,
        tagline,
        relevantTags,
        description,
        infoTables,
        link,
        saved,
        icons
    } = card;

    const copyLink = () => {
        navigator.clipboard.writeText(link);
        setShowCopyMsg(true);
        setTimeout(() => {
            setShowCopyMsg(false);
        }, 2000);
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div className={classNames('spotlight-card', { 'condensed': !isExpanded })}>
            <div className='top-container'>
                <div className='topics-container'>
                    {icons.map((Icon, index) => <SpotlightTopicIcon key={index} Icon={Icon} />)}
                </div>
                <div className='distance'>{distance}</div>
            </div>
            <div onClick={handleClick} className='titles'>
                <h1>{title}</h1>
                <h2>{tagline}</h2>
            </div>
            <div className='tags-container'>
                {relevantTags.map((tag, index) => <div key={index} className='tag info-tag'>{tag}</div>)}
                {/* {relevantTags.map((tag, index) => <div key={index} className='tag issue-tag'>{tag}</div>)} */}
            </div>
            <div className='description-text'>{description}</div>
            {isExpanded &&
                <div className='info-tables-container'>
                    {infoTables.map((tableData, index) => <SpotlightCardInfoTable key={index} data={tableData} />)}
                </div>}
            <div className='bottom-container'>
                <div className='copy-link-container'>
                    {/* {showCopiedMsg && <div className='copied-msg'>link copied!</div>} */}
                    <button onClick={copyLink} className='copy-btn'><Icons.Copy /></button>
                    <div onClick={copyLink} className='resource-link'>Website Link</div>
                </div>
                <button onClick={() => toggleSave(id)} className={classNames('save-btn', { 'active': saved })}><Icons.Bookmark /></button>
            </div>
        </div>
    );
}

export default SpotlightCard;