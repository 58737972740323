import { useEffect, useRef, useCallback } from 'react';

export function useParticipantsChange(client, func) {
    const funcRef = useRef(func);
    funcRef.current = func;

    const callback = useCallback((updatedParticipants) => {
        const participants = client.getAllUser();
        funcRef.current?.(participants, updatedParticipants);
    }, [client]);

    useEffect(() => {
        client.on('user-added', callback);
        client.on('user-removed', callback);
        return () => {
            client.off('user-added', callback);
            client.off('user-removed', callback);
        };
    }, [client, callback]);

    useEffect(() => {
        callback();
    }, []);
}