import React, { useState } from 'react';
import './ContentRectangle.css'; 
import WorkerModal from './WorkerModal';
import { ref, get, set, remove } from 'firebase/database';
import { database } from '../firebase'; 

const ContentRectangle = ({ name, description, psworker, status, linkType, itemId, onRemove, onWorkerChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClick = async (action) => {
    onRemove(itemId);  // Remove item from the UI instantly

    const currentRef = ref(database, `${linkType}/${itemId}`);
    
    let newRefPath;
    switch(action) {
      case "match":
        newRefPath = `ongoing/${itemId}`;
        break;
      case "followup":
        newRefPath = `followup/${itemId}`;
        break;
      default:
        return;
    }
    const newRef = ref(database, newRefPath);

    const snapshot = await get(currentRef);
    const data = snapshot.val();
    if (data) {
      await set(newRef, data);
      await remove(currentRef);
    }
  };

  const handleWorkerChange = async (newWorker) => {
    const currentRef = ref(database, `${linkType}/${itemId}`);
    const snapshot = await get(currentRef);
    const data = snapshot.val();
    if (data) {
      await set(currentRef, { ...data, psworker: newWorker });
      // Notify the parent component of the worker change
      onWorkerChange(itemId, newWorker);
    }
    setIsModalVisible(false);
  };

  let buttons;

  switch(linkType) {
    case "backlog":
      buttons = (
        <>
          <button className="button" onClick={() => handleButtonClick("match")}>Match with {psworker}</button>
          <button className="button" onClick={() => setIsModalVisible(true)}>
            Pair with a different worker
          </button>
        </>
      );
      break;
    case "ongoing":
      buttons = <button className="button" onClick={() => handleButtonClick("followup")}>Followup with {psworker}</button>;
      break;
    case "completed":
      buttons = <button className="button">Request Feedback</button>;
      break;
    default:
      buttons = null;
  }

  return (
    <div className="content-rectangle">
      <h3 className="name">{name}</h3>
      <h4 className="status"> Status: {status}</h4>
      <p className="description">{description}</p>
      <div className="button-container">
        {buttons}
      </div>
      <WorkerModal 
        isVisible={isModalVisible} 
        onSelect={handleWorkerChange} 
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default ContentRectangle;
