import { useState, useCallback, useEffect } from 'react';
import { useParticipantsChange } from './useParticipantsChange';

// Relevant documentation
// https://marketplacefront.zoom.us/sdk/custom/web/interfaces/ChatUserItem.html
// https://marketplacefront.zoom.us/sdk/custom/web/interfaces/ChatMessage.html

export function useChat(client, chatClient) {
    const [isHost, setIsHost] = useState(false);
    const [inChat, setInChat] = useState(false);
    const [chatUser, setChatUser] = useState(null); // ChatUserItem
    const [receivers, setReceivers] = useState([]); // ChatUserItem[]
    const [records, setRecords] = useState([]); // ChatMessage[]
    const [latestMessages, setLatestMessages] = useState(new Map()); // key: userId, value: ChatMessage
    const [unreadCountsByThread, setUnreadCountsByThread] = useState(new Map()); // key: userId, value: number
    const [totalUnread, setTotalUnread] = useState(0);
    const [chatThreads, setChatThreads] = useState([]); // { receiver: ChatUserItem, latestMessage: ChatMessage }[]

    const onChatMessage = useCallback((messageRecord) => {
        // update chat history
        setRecords(prev => [...prev, messageRecord]);
        // update collection of latest messages
        setLatestMessages(prev => {
            const newMap = new Map(prev);
            newMap.set(messageRecord.sender.userId, messageRecord);
            newMap.set(messageRecord.receiver.userId, messageRecord);
            return newMap;
        });
        // update unread messages count
        if (!inChat || (inChat && chatUser?.userId !== messageRecord.sender.userId)) {
            setUnreadCountsByThread(prev => {
                const count = prev.get(messageRecord.sender.userId) ?? 0;
                const newMap = new Map(prev);
                newMap.set(messageRecord.sender.userId, count + 1);
                return newMap;
            });
        }
    }, [chatUser, inChat]);

    const sendMessage = useCallback((text) => {
        if (chatUser) {
            chatClient.send(text, chatUser.userId);
        }
    }, [chatUser, chatClient]);

    const sendAnnouncement = useCallback((text) => {
        chatClient.sendToAll(text);
    }, [chatClient]);

    const isSenderOrReceiver = useCallback((chatMessage, userId) => {
        return (chatMessage.receiver.userId === userId || chatMessage.sender.userId === userId);
    }, []);

    const isAnnouncement = useCallback((chatMessage) => {
        return chatMessage.receiver.userId === 0;
    }, []);

    const getLatestMessage = useCallback((userId) => {
        for (let i = records.length - 1; i >= 0; i--) {
            const chatMessage = records[i];
            if (isSenderOrReceiver(chatMessage, userId)) {
                return chatMessage;
            }
        }
    }, [records, isSenderOrReceiver]);

    const setChatUserId = useCallback((userId) => {
        const user = receivers.find(user => user.userId === userId);
        if (user) {
            setChatUser(user);
        }
    }, [receivers]);

    useParticipantsChange(client, () => {
        if (chatClient) {
            // Don't include the 'Everyone' receiver at index 0
            setReceivers(chatClient.getReceivers().slice(1));
        }
        setIsHost(client.isHost());
    });

    useEffect(() => {
        let updatedChatThreads = [];
        let updatedTotalUnread = 0;
        for (const receiver of receivers) {
            const numUnread = unreadCountsByThread.get(receiver.userId) ?? 0;
            updatedChatThreads.push({
                receiver: receiver,
                latestMessage: latestMessages.get(receiver.userId),
                numUnread: numUnread
            });
            updatedTotalUnread += numUnread;
        }
        setChatThreads(updatedChatThreads);
        setTotalUnread(updatedTotalUnread);
    }, [receivers, latestMessages, unreadCountsByThread]);

    useEffect(() => {
        if (isHost) {
            if (chatUser) {
                const index = receivers.findIndex((user) => user.userId === chatUser.userId);
                if (index === -1) {
                    setChatUser(receivers[0]);
                }
            } else {
                setChatUser(receivers[0]);
            }
        } else {
            const host = client.getSessionHost();
            if (host) {
                setChatUserId(host.userId);
            }
        }
    }, [client, receivers, chatUser, isHost, setChatUserId]);

    useEffect(() => {
        if (chatUser) {
            setUnreadCountsByThread(prev => new Map(prev).set(chatUser.userId, 0));
        }
    }, [chatUser, inChat]);

    useEffect(() => {
        client.on('chat-on-message', onChatMessage);
        return () => client.off('chat-on-message', onChatMessage);
    }, [client, onChatMessage]);

    return {
        isHost,
        chatUser,
        records,
        chatThreads,
        totalUnread,
        inChat,
        setInChat,
        sendMessage,
        sendAnnouncement,
        setChatUserId,
        isSenderOrReceiver,
        isAnnouncement,
        getLatestMessage
    };
}