import { useContext, useEffect, useState } from 'react';
import { ClientContext } from '../../contexts/zoom-context';
import { SubsessionAllocationPattern, SubsessionStatus } from '@zoom/videosdk';
import ErrorMessage from '../common/ErrorMessage';
import { logError } from '../../util/util';

import './BreakoutRoomsPage.css';

// 50 is the max subsessions allowed by Zoom Video SDK
const MAX_ROOMS = 50;

const BreakoutRoomsPage = ({ className }) => {
    const client = useContext(ClientContext);
    const subsession = client.getSubsessionClient();
    const [numRoomsStr, setNumRoomsStr] = useState('');
    const [durationStr, setDurationStr] = useState('');
    const [topicStr, setTopicStr] = useState('');
    const [breakoutRoomsOpen, setBreakoutRoomsOpen] = useState(subsession.getSubsessionStatus() === SubsessionStatus.InProgress);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const onSubsessionStateChange = ({ status }) => {
            setBreakoutRoomsOpen(status === SubsessionStatus.InProgress);
        }
        client.on('subsession-state-change', onSubsessionStateChange);
        return () => client.off('subsession-state-change', onSubsessionStateChange);
    }, []);

    const inputsAreValid = () => {
        const numRooms = Number.parseInt(numRoomsStr);
        if (Number.isNaN(numRooms) || numRooms <= 0) {
            setError(true);
            setErrorMsg('Please enter a valid number of breakout rooms.');
            return false;
        }
        if (numRooms > MAX_ROOMS) {
            setError(true);
            setErrorMsg(`You've exceeded the maximum number (${MAX_ROOMS}) of breakout rooms.`);
            return false;
        }

        const durationInMins = Number.parseInt(durationStr);
        if (Number.isNaN(durationInMins) || durationInMins <= 0) {
            setError(true);
            setErrorMsg('Please enter a valid breakout room duration');
            return false;
        }

        return true;
    }

    const createBreakoutRooms = async () => {
        if (!inputsAreValid()) {
            return;
        } else {
            setError(true);
            setErrorMsg('Sorry! The breakout rooms feature is under construction.');
            return;
        }

        const numRooms = Number.parseInt(numRoomsStr);
        const durationInSec = Number.parseInt(durationStr) * 60;

        let data = [];
        for (let i = 0; i < numRooms; i++) {
            data.push('Breakout Room ' + (i + 1));
        }

        return subsession.createSubsessions(data, SubsessionAllocationPattern.Automatically)
            .then((subsessions) => {
                console.log('subsessions created');
                return subsession.openSubsessions(subsessions, {
                    isAutoJoinSubsession: true,
                    isBackToMainSessionEnabled: true,
                    isTimerEnabled: true,
                    timerDuration: durationInSec,
                    isTimerAutoEnabled: true,
                    waitSeconds: 0
                }).then(() => console.log('subsessions opened'));
            })
            .catch(err => {
                console.error(err);
                logError(err);
            });
    }

    const closeBreakoutRooms = async () => {
        if (client.isHost()) {
            return subsession.closeAllSubsessions()
                .then(() => {
                    console.log('breakout rooms closed');
                })
                .catch(err => {
                    console.error(err);
                    logError(err);
                });
        }
    }

    return (
        <div className={`${className} breakout-rooms`}>
            <h1>Create Breakout Rooms</h1>
            <form>
                <div className='form-entry'>
                    <label>Number of Rooms</label>
                    <input className='num-rooms-input' type='text' onChange={(e) => setNumRoomsStr(e.target.value.trim())} />
                </div>
                <div className='form-entry'>
                    <label>Set Duration</label>
                    <input className='duration-input' type='text' onChange={(e) => setDurationStr(e.target.value.trim())} />
                    <div className='right-label'>minutes</div>
                </div>
                <div className='form-entry'>
                    <label>Set Topic For Discussion</label>
                    <input className='topic-input' type='text' onChange={(e) => setTopicStr(e.target.value.trim())} />
                </div>
            </form>
            {error && <ErrorMessage message={errorMsg} />}
            {breakoutRoomsOpen ?
                <button onClick={closeBreakoutRooms}>Close Rooms</button> :
                <button onClick={createBreakoutRooms}>Create Rooms</button>}
        </div>
    );
}

export default BreakoutRoomsPage;