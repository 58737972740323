import React, { useState } from 'react';
import './WorkerEditModal.css';

const WorkerEditModal = ({ isVisible, onSelect, onClose, name, email, description }) => {
  const [formData, setFormData] = useState({ name, email, description });
  
  if (!isVisible) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Added this line
    onSelect(formData);
    onClose(); // Close the modal after selecting
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h3>Edit Worker</h3>
        <form onSubmit={handleFormSubmit}>
          <label for="name">Name:</label> {/* Added label */}
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleInputChange} 
          /><br/>

          <label for="email">Email:</label> {/* Added label */}
          <input 
            type="email" 
            id="email"
            name="email" 
            value={formData.email} 
            onChange={handleInputChange} 
          /><br/>

          <label for="description">Description:</label> {/* Added label */}
          <textarea 
            id="description"
            name="description" 
            value={formData.description} 
            onChange={handleInputChange} 
          /><br/>

          <button type="submit">Save</button>
        </form>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default WorkerEditModal;