import { useContext } from 'react';
import { ClientContext } from '../contexts/zoom-context';
import * as Icons from './common/Icons';
import './ParticipantList.css';

const Checkbox = ({ isChecked, onClick }) => {
    return (
        <button onClick={onClick} className={`checkbox ${isChecked ? 'checked' : undefined}`}>
            {isChecked && <Icons.Checkmark />}
        </button>
    );
}

const AVStatus = ({ audioMuted, videoOff }) => {
    return (
        <div className='av-status'>
            <div className='audio-status'>
                {audioMuted ?
                    <Icons.MicrophoneOff /> :
                    <Icons.MicrophoneOn />}
            </div>
            <div className='video-status'>
                {videoOff ?
                    <Icons.CameraOff /> :
                    <Icons.CameraOn />}
            </div>
        </div>
    );
}

const Item = ({
    participant,
    isSelectable,
    isSelected,
    toggleSelect,
    showAVStatus,
    showMessageButton,
    onMessageButtonClick
}) => {
    const client = useContext(ClientContext);
    const hasAVStatus = (participant.bVideoOn !== undefined);
    const isCurrentUser = client.getCurrentUserInfo() && (participant.userId === client.getCurrentUserInfo().userId);

    return (
        <>
            {isSelectable ?
                <div className={`item ${isSelected ? 'selected' : undefined}`}>
                    <div className='checkbox-name-container'>
                        {isCurrentUser ?
                            <div className='spacer'></div> :
                            <Checkbox onClick={() => toggleSelect(participant)} isChecked={isSelected} />
                        }
                        {participant.displayName}
                    </div>
                    {showMessageButton && <button onClick={() => onMessageButtonClick(participant.userId)} className='message-btn'>Message</button>}
                    {(showAVStatus && hasAVStatus) && <AVStatus audioMuted={participant.muted || participant.muted === undefined} videoOff={!participant.bVideoOn} />}
                </div>
                :
                <div className='item'>
                    {participant.displayName}
                    {showMessageButton && <button onClick={() => onMessageButtonClick(participant.userId)} className='message-btn'>Message</button>}
                    {(showAVStatus && hasAVStatus) && <AVStatus audioMuted={participant.muted || participant.muted === undefined} videoOff={!participant.bVideoOn} />}
                </div>
            }
        </>
    )
}

// participantList structure is one of:
//      Participant[] (https://marketplacefront.zoom.us/sdk/custom/web/interfaces/Participant.html)
//      { displayName: string }[]
const ParticipantList = ({
    participantList,
    selectedSet,
    setSelectedSet,
    showAVStatus = true,
    showMessageButton = false,
    onMessageButtonClick
}) => {
    const isSelectable = (selectedSet !== null) && (selectedSet !== undefined);

    const toggleSelectParticipant = (participant) => {
        const updatedSet = new Set(selectedSet);
        if (updatedSet.has(participant.userId)) {
            updatedSet.delete(participant.userId);
        } else {
            updatedSet.add(participant.userId);
        }
        setSelectedSet(updatedSet);
    }

    return (
        <div className='participant-list'>
            {participantList.map((participant, index) => {
                let isSelected = false;
                if (isSelectable && participant.userId) {
                    isSelected = selectedSet.has(participant.userId);
                }
                return (
                    <Item
                        key={index}
                        participant={participant}
                        isSelectable={isSelectable}
                        isSelected={isSelected}
                        toggleSelect={toggleSelectParticipant}
                        showAVStatus={showAVStatus}
                        showMessageButton={showMessageButton}
                        onMessageButtonClick={onMessageButtonClick}
                    />
                );
            })}
        </div>
    );
}

export default ParticipantList;