import { useContext, useEffect, useState } from 'react';
import { ClientContext, CrisisContext } from '../contexts/zoom-context';
import { ExclamationTriangle, X } from './common/Icons';
import { signalCrisis } from '../util/meetingHelpers';
import CrisisBarPopup from './CrisisBarPopup';

import './CrisisBar.css';

const CrisisBar = () => {
    const client = useContext(ClientContext);
    const { isHost, crisisCount } = useContext(CrisisContext);
    const [isActive, setActive] = useState(false);
    const [showNotifDot, setShowNotifDot] = useState(crisisCount > 0);
    const [showHoverText, setShowHoverText] = useState(false);

    useEffect(() => {
        setShowNotifDot(crisisCount > 0);
    }, [crisisCount]);

    const handleClick = (e) => {
        setActive(!isActive);
        signalCrisis(client, !isActive);
    }

    if (isHost) {
        return (
            <div className='crisis-bar'>
                {showNotifDot && <div className='notif-dot'>{crisisCount}</div>}
                <button onClick={() => setActive(prev => !prev)} className={`crisis-button host ${isActive ? 'active' : undefined}`}>
                    <ExclamationTriangle />
                </button>
                {showNotifDot && <div className='notif-spacer' />}
                {isActive && <CrisisBarPopup />}
            </div>
        );
    } else {
        return (
            <div className='crisis-bar'>
                <button
                    onClick={handleClick}
                    onMouseEnter={(e) => setShowHoverText(true)}
                    onMouseLeave={(e) => setShowHoverText(false)}
                    className={`crisis-button participant ${isActive ? 'active' : undefined}`}>
                    <ExclamationTriangle />
                </button>
                {(showHoverText && !isActive) &&
                    <div className='hover-text'>
                        Click the icon to alert the host if
                        you're in need of immediate support.
                    </div>
                }
                {isActive &&
                    <>
                        <div className='active-msg'>
                            You have alerted the host.
                        </div>
                        <button onClick={handleClick} className='cancel-btn'>
                            <X />
                            <div>Cancel Alert</div>
                        </button>
                    </>
                }
            </div>
        );
    }
}

export default CrisisBar;
