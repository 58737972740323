import { logError } from './util';

export function isCurrentUser(client, user) {
    return (client?.getCurrentUserInfo() && client.getCurrentUserInfo().userId === user.userId);
}

export function includesDeviceWithId(deviceList, activeDeviceId) {
    for (const device of deviceList) {
        if (device.deviceId === activeDeviceId) {
            return true;
        }
    }
    return false;
}

export function getTodayAtTime(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(' ')[0].split(':').map(Number);
    const today = new Date();
    return new Date(today.setUTCHours(hours, minutes, seconds));
}

// Debug
export function printDebugInfo(client, numParticipants) {
    console.log('---------------------');
    for (const user of client.getAllUser()) {
        console.log(user.displayName,
            '\n    ', 'userId:', user.userId,
            '\n    ', 'ME:', user.userId === client.getCurrentUserInfo().userId,
            // '\n    ', 'Camera On:', user.bVideoOn,
            '\n    ', 'Audio On:', user.audio,
            '\n    ', 'Muted:', user.muted,
            '\n    ', 'isHost:', user.isHost,
            '\n    ', 'isManager:', user.isManager,
        );
    }
    console.log('# Participants:', numParticipants);
}

export function printAVInfo(client, cameraId, micId, speakerId) {
    const stream = client.getMediaStream();
    console.log('---------------------');
    console.log('CAMERAS');
    for (const device of stream.getCameraList()) {
        console.log(device.label, '(' + device.deviceId + ')');
    }
    console.log('ACTIVE CAMERA:', cameraId);
    console.log('---------------------');
    console.log('MICS');
    for (const device of stream.getMicList()) {
        console.log(device.label, '(' + device.deviceId + ')');
    }
    console.log('ACTIVE MIC:', micId);
    console.log('---------------------');
    console.log('SPEAKERS');
    for (const device of stream.getSpeakerList()) {
        console.log(device.label, '(' + device.deviceId + ')');
    }
    console.log('ACTIVE SPEAKER:', speakerId);
}

export function subsessionStatusString(status) {
    switch (status) {
        case 1:
            return 'Not Started';
        case 2:
            return 'In Progress';
        case 3:
            return 'Closing';
        case 4:
            return 'Closed';
        default:
            return undefined;
    }
}

export function printBreakoutRoomDebugInfo(client) {
    const subsession = client.getSubsessionClient();
    console.log('Subsession Status:', subsessionStatusString(subsession.getSubsessionStatus()));
    console.log('User Status:', subsession.getUserStatus());
    for (const el of subsession.getSubsessionList()) {
        console.log(el.subsessionName);
        for (const user of el.userList) {
            console.log('\n    ', user.displayName);
        }
    }
}

export function printArray(arr) {
    console.log('---------------------');
    for (let i = 0; i < arr.length; i++) {
        console.log(i + ':', arr[i]);
    }
}

// User session actions
export const Events = Object.freeze({
    TURN_OFF_CAMERA_CMD: 'turn-off-camera',
    TAKE_BREAK_CMD: 'take-a-break',
    TAKE_BREAK_NOTIF: 'user-on-break',
    RETURN_FROM_BREAK_NOTIF: 'user-returned-from-break',
    CRISIS_NOTIF: 'crisis',
    CANCEL_CRISIS_NOTIF: 'crisis-cancelled',
});

export async function muteUsers(client, userIds) {
    const stream = client.getMediaStream();
    for (const userId of userIds) {
        await stream.muteAudio(userId)
            .catch(error => {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
    }
}

export async function removeUsers(client, userIds) {
    for (const userId of userIds) {
        await client.removeUser(userId)
            .catch(error => {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
    }
}

export async function turnOffCamerasOfUsers(client, userIds) {
    const cmdClient = client.getCommandClient();
    for (const userId of userIds) {
        await cmdClient.send(Events.TURN_OFF_CAMERA_CMD, userId)
            .catch(error => {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
    }
}

export async function adjustAllUserAudioVolumeLocally(client, volume) {
    const stream = client.getMediaStream();
    for (const user of client.getAllUser()) {
        await stream.adjustUserAudioVolumeLocally(user.userId, volume);
    }
}

// trigger break mode event on user's client which will
//      mute their mic,
//      stop their video, 
//      lower their session's local volume to 5%,
//      show a message saying they are on a break
async function sendUserOnBreak(client, userId) {
    const cmdClient = client.getCommandClient();
    await cmdClient.send(Events.TAKE_BREAK_CMD, userId);
}

export async function sendUsersOnBreak(client, userIds) {
    for (const userId of userIds) {
        await sendUserOnBreak(client, userId)
            .catch(error => {
                console.error(error);
                logError(error, { user: client?.getCurrentUserInfo()?.userId + ' (' + client?.getCurrentUserInfo()?.displayName + ')' });
            });
    }
}

export async function signalCrisis(client, inCrisis) {
    const host = client.getSessionHost();
    if (host) {
        const cmdClient = client.getCommandClient();
        const msg = (inCrisis) ? Events.CRISIS_NOTIF : Events.CANCEL_CRISIS_NOTIF;
        return cmdClient.send(msg, host.userId);
    } else {
        console.error('No host in session, cannot signal crisis');
    }
}