import './SpotlightTopicIcon.css';

const SpotlightTopicIcon = ({ Icon }) => {
    return (
        <div className='spotlight-topic-icon'>
            <Icon />
        </div>
    )
}

export default SpotlightTopicIcon;