import React, { useState } from 'react';
import './WorkerModal.css';  // Reusing the same CSS

const WorkerCreateModal = ({ isVisible, onCreate, onClose }) => {
  const [formData, setFormData] = useState({ name: '', email: '', description: '' });

  if (!isVisible) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h3>Create Worker</h3>
        <form onSubmit={handleFormSubmit}>
          <label for="name">Name:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleInputChange} 
          /><br/>

          <label for="email">Email:</label>
          <input 
            type="email" 
            id="email"
            name="email" 
            value={formData.email} 
            onChange={handleInputChange} 
          /><br/>

          <label for="description">Description:</label>
          <textarea 
            id="description"
            name="description" 
            value={formData.description} 
            onChange={handleInputChange} 
          /><br/>

          <button type="submit">Create</button>
        </form>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default WorkerCreateModal;
