import React, { useState, useEffect } from 'react';
import { ref, push, set, get } from 'firebase/database';
import { database } from '../firebase'; 
import { useNavigate } from 'react-router-dom';
import './Explore.css';

const Explore = () => {
    const [supportRequest, setSupportRequest] = useState('');
    const navigate = useNavigate();
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const fetchPrograms = () => {
            const dbRef = ref(database, 'programs');
            get(dbRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setPrograms(snapshot.val());
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        };

        fetchPrograms();
    }, []);

    const handleRegister = (programName) => {
        window.location.href = `https://krasmancentre.peersupport.io/program-form/${programName}`;
    };
  
    const handleGriefRegister = () => {
      window.location.href = "https://krasmancentre.peersupport.io/program-form/Coping-with-Grief";
    };
  
    const handleToughStuffRegister = () => {
      window.location.href = "https://krasmancentre.peersupport.io/program-form/Tough-stuff";
    };
  
    const handleContinue = () => {
      navigate('/request-form');
    };
  
 return (
    <div className="app">
      
        <svg xmlns="http://www.w3.org/2000/svg" width="1082" height="1155" viewBox="0 0 1082 1155" fill="none" className="left-svg" /* Add the rest of your SVG properties here */>
        <g opacity="0.25" filter="url(#filter0_f_37_29)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.9487 732.776C-24.7742 460.085 152.068 190.891 430.936 131.513L935.872 24L1045.82 517.75C1106.54 790.44 929.702 1059.63 650.833 1119.01C371.965 1178.39 96.6717 1005.47 35.9487 732.776Z" fill="url(#paint0_linear_37_29)"/>
        </g>
        <defs>
            <filter id="filter0_f_37_29" x="0.00830078" y="0" width="1081.75" height="1154.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_37_29"/>
            </filter>
            <linearGradient id="paint0_linear_37_29" x1="279.06" y1="1049.21" x2="914.946" y2="11.1808" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF3E6E" stop-opacity="0.53"/>
            <stop offset="0.46875" stop-color="#FF3E6E" stop-opacity="0.28"/>
            <stop offset="0.788797" stop-color="#FF7461" stop-opacity="0"/>
            </linearGradient>
        </defs>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="1241" height="1452" viewBox="0 0 1241 1452" fill="none" className="right-svg" /* Add the rest of your SVG properties here */>
  <g opacity="0.3" filter="url(#filter0_f_37_32)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M251.879 1314.32C-13.1509 1115.28 -62.9898 743.835 140.561 484.676L509.121 15.4284L989 375.824C1254.03 574.865 1303.87 946.309 1100.32 1205.47C896.768 1464.63 516.909 1513.36 251.879 1314.32Z" fill="url(#paint0_linear_37_32)"/>
  </g>
  <defs>
    <filter id="filter0_f_37_32" x="0.330078" y="0.428467" width="1240.22" height="1451.35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_37_32"/>
    </filter>
    <linearGradient id="paint0_linear_37_32" x1="982.521" y1="1271.29" x2="367.095" y2="499.546" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF3E6E" stop-opacity="0.53"/>
      <stop offset="1" stop-color="#7F3DF6" stop-opacity="0"/>
    </linearGradient>
  </defs>
      </svg>
      {/* First Section */}
      <section className="section">
      <div className="form-logo-container">
            <img 
                src="https://krasmancentre.com/wp-content/uploads/2018/03/Logo-Krasman.png" 
                alt="Logo" 
                className="formlogo" 
                />
      </div>
        <h1>Welcome to the Krasman Centre</h1>
        <p>
          Krasman Centre is an organization created, led and run by People with Lived/Living Experiences, of mental health and/or substance use/addiction challenges, and family members. All our programs are offered from a Peer Support-based approach.  We serve people in York Region, County of Simcoe and areas of Toronto. <br></br> <em> Our mission: To support and empower people affected by mental health and/or addiction challenges by providing inclusive peer-support programs. </em>
        </p>
      </section>

      {/* Second Section */}
      <section className="section">
        <h2>Unsure where to start? We are here for you </h2>
        <label htmlFor="story">It's truly important to us that you feel heard and supported. We kindly ask you to share some details via this form. The information will enable us to match you with a peer supporter who can best understand and assist you in your journey. Remember, you're not alone, and we're here to walk this path with you.</label>
        <button className="submitButton" type="button" onClick={handleContinue}>Continue</button>
    </section>

      {/* Second Section */}
      <section className="section">
            <h2>Our Programs</h2>
            {Object.entries(programs)
                .filter(([programName, _]) => programName.toLowerCase() !== "names")
                .map(([programName, programDetails]) => (
                    <div className="box" key={programName}>
                        <h3>{programDetails.title || programName}</h3>
                        <p>{programDetails.description}</p>
                        <button className="submitButton" type="button" onClick={() => handleRegister(programName)}>Register</button>
                    </div>
                ))
            }
        </section>

    </div>
  );
}

export default Explore;
