import { useContext, useState, useTransition, useEffect } from 'react';
import { ChatContext, SidebarContext } from '../../contexts/zoom-context';
import SidebarContentPage from './SidebarContentPage';
import SidebarToggle from './SidebarToggle';
import {
    AgendaButton,
    ParticipantsButton,
    AbsenteesButton,
    BreakoutRoomsButton,
    ScreenShareButton,
    ChatButton,
    SpotlightButton
} from './SidebarHeaderButton';

import './Sidebar.css';

export const Headers = Object.freeze({
    AGENDA: 0,
    PARTICPANTS: 1,
    ABSENTEES: 2,
    CHAT: 3,
    BREAKOUT_ROOMS: 4,
    SCREENSHARE: 5,
    SPOTLIGHT: 6
});

const Sidebar = () => {
    const {
        sidebarOpen,
        setSidebarOpen,
        isHost,
        isWaitingRoom,
        sidebarHeader,
        setSidebarHeaderExternally
    } = useContext(SidebarContext);
    const { setInChat } = useContext(ChatContext);
    const [activeHeader, setActiveHeader] = useState(Headers.AGENDA);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        if (sidebarHeader) {
            selectHeader(sidebarHeader);
            setSidebarHeaderExternally(undefined);
        }
    }, [sidebarHeader]);

    function selectHeader(header) {
        startTransition(() => {
            setActiveHeader(header);
            setInChat(header === Headers.CHAT);
        });
    }

    return (
        <div className={`vc-sidebar-container ${sidebarOpen ? undefined : 'closed'}`}>
            <div className='vc-sidebar'>
                <div className='side-container'>
                    <div className='header-buttons'>
                        <AgendaButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                        {!isWaitingRoom &&
                            <>
                                <ParticipantsButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                                <AbsenteesButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                                <BreakoutRoomsButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                                <ScreenShareButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                                <ChatButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                                <SpotlightButton showLabel={!sidebarOpen} selectHeader={selectHeader} activeHeader={activeHeader} />
                            </>
                        }
                    </div>
                    <SidebarToggle isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
                </div>
                {sidebarOpen && <SidebarContentPage header={activeHeader} />}
            </div>
        </div>
    );
}

export default Sidebar;