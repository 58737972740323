import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientContext, SidebarContext } from '../contexts/zoom-context';
import WelcomePopup from '../components/WelcomePopup';
import MeetingModal from '../components/MeetingModal';
import ErrorModal from '../components/common/ErrorModal';
import IOButton from '../components/IOButton';
import MeetingButton, { MeetingButtonStates } from '../components/MeetingButton';
import TimeDisplay from '../components/TimeDisplay';
import Sidebar from '../components/Sidebar/Sidebar';
import { CameraOptionsPanel, MicrophoneOptionsPanel, AudioOptionsPanel } from '../components/IOOptionsPanel';
import * as Icons from '../components/common/Icons';
import { getToken, errorToString, logError } from '../util/util';
import { getTodayAtTime, includesDeviceWithId } from '../util/meetingHelpers';
import { devConfig, prodConfig } from '../config/devConfig';
import { usePreview } from '../hooks/usePreview';

import './WaitingRoom.css';

// TODO: fetch real info from db to populate meetingArgs
let meetingArgs = (process.env.NODE_ENV === 'development') ? { ...devConfig } : { ...prodConfig };
// Option to connect to prod meeting from development environment
// https://krasmancentre.peersupport.io/video_conferencing/?session=prod
const sessionParam = new URLSearchParams(window.location.search).get('session');
if (process.env.NODE_ENV === 'development' && sessionParam?.toLowerCase() === 'prod') {
    meetingArgs = { ...prodConfig };
}

const WaitingRoom = () => {
    const { sessionName, password, role, startTime, durationInMin } = meetingArgs;
    const navigate = useNavigate();
    const client = useContext(ClientContext);

    const isSAB = window.crossOriginIsolated;
    const startDate = getTodayAtTime(startTime);
    const isPotentialHost = (role === 1);

    // Start/Join Modal messages
    const beforeAfterStartMsg = 'Are you sure you want to start the meeting?';
    const atStartMsg = 'It\'s time for the meeting! Are you ready to start the meeting?';
    const joinMsg = 'It\'s time for the meeting! Are you ready to join?';

    const [isPastStart, setIsPastStart] = useState(() => new Date() > startDate);
    const [meetingButtonState, setMeetingButtonState] = useState(() => {
        if (isPotentialHost) return MeetingButtonStates.START_ACTIVE;
        return (isPastStart ? MeetingButtonStates.START_ACTIVE : MeetingButtonStates.START_INACTIVE);
    });

    const [isWelcomePopupOpen, setWelcomePopupOpen] = useState(true);
    const [isStartModalOpen, setStartModalOpen] = useState(false);
    const [isErrorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('An error occured with X. Please close and reopen the window to try again');
    const [modalMessage, setModalMessage] = useState(isPotentialHost ? beforeAfterStartMsg : joinMsg);
    const [onModalClose, setOnModalClose] = useState(() => () => { });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [displayName, setDisplayName] = useState((process.env.NODE_ENV === 'development') ? 'Jane Doe' : '');
    const [pronouns, setPronouns] = useState((process.env.NODE_ENV === 'development') ? 'They/Them' : '');

    const [disablePreviewAndSourceButtons, setDisablePreviewAndSourceButtons] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [videoButtonDisabled, setVideoButtonDisabled] = useState(false);

    const videoRef = useRef(null);
    const [cameraDevices, setCameraDevices] = useState([{ label: 'No Cameras Found' }]);
    const [micDevices, setMicDevices] = useState([{ label: 'No Microphones Found' }]);
    const [speakerDevices, setSpeakerDevices] = useState([{ label: 'No Speakers Found' }]);
    const {
        volume,
        previewCameraId,
        previewMicId,
        previewSpeakerId,
        cameraPreviewStarted,
        micPreviewStarted,
        speakerPreviewStarted,
        fetchDevices,
        previewCamera,
        previewMic,
        previewSpeaker,
        switchPreviewCamera,
        switchPreviewMic,
        switchPreviewSpeaker
    } = usePreview(videoRef);

    let cameraPanelProps = {
        deviceList: cameraDevices,
        activeDeviceId: previewCameraId,
        switchSource: switchPreviewCamera
    };
    let microphonePanelProps = {
        deviceList: micDevices,
        activeDeviceId: previewMicId,
        switchSource: switchPreviewMic,
        previewStarted: micPreviewStarted,
        previewDevice: previewMic,
        currVolume: volume
    };
    let speakerPanelProps = {
        deviceList: speakerDevices,
        activeDeviceId: previewSpeakerId,
        switchSource: switchPreviewSpeaker,
        previewStarted: speakerPreviewStarted,
        previewDevice: previewSpeaker,
        currVolume: volume
    };

    const sidebarContextValue = {
        sidebarOpen,
        setSidebarOpen,
        isWaitingRoom: true,
        isHost: isPotentialHost,
    }

    // Called once on render to fetch AV devices
    useEffect(() => {
        refreshDevices();
    }, []);

    // Event listener to detect when devices are plugged in or unplugged
    useEffect(() => {
        navigator.mediaDevices.addEventListener('devicechange', refreshDevices);
        return () => navigator.mediaDevices.removeEventListener('devicechange', refreshDevices);
    }, []);

    // Listen for meeting start time to prompt join
    useEffect(() => {
        if (isPastStart && !isWelcomePopupOpen) {
            if (isPotentialHost) {
                setModalMessage(atStartMsg);
                setOnModalClose(() => () => setModalMessage(beforeAfterStartMsg));
            }
            setStartModalOpen(true);
            setMeetingButtonState(MeetingButtonStates.START_ACTIVE);
        }
    }, [isPastStart]);

    async function refreshDevices() {
        setDisablePreviewAndSourceButtons(true);
        let { cameras, mics, speakers } = await fetchDevices();
        setCameraDevices(cameras);
        setMicDevices(mics);
        setSpeakerDevices(speakers);

        if (cameras && cameras.length > 0 && !includesDeviceWithId(cameras, previewCameraId)) {
            switchPreviewCamera(cameras[0].deviceId);
        }
        if (mics && mics.length > 0 && !includesDeviceWithId(mics, previewMicId)) {
            switchPreviewMic(mics[0].deviceId);
        }
        if (speakers && speakers.length > 0 && !includesDeviceWithId(speakers, previewSpeakerId)) {
            switchPreviewSpeaker(speakers[0].deviceId);
        }
        setDisablePreviewAndSourceButtons(false);
    }

    function openErrorModal(error) {
        console.error(error);
        setErrorMessage(errorToString(error));
        setErrorModalOpen(true);
    }

    const onCameraClick = async () => {
        setVideoButtonDisabled(true);
        try {
            await previewCamera();
        } catch (error) {
            openErrorModal(error);
            logError(error, { user: displayName });
        } finally {
            setVideoButtonDisabled(false);
        }
    };

    const initAndJoinSession = useCallback(async () => {
        // Generate access token
        let signature = await getToken(meetingArgs);
        let name = `${displayName} (${pronouns})`;
        // Initialize client
        await client.init('en-US', 'Global', {
            enforceMultipleVideos: !isSAB,
            leaveOnPageUnload: true,
            patchJsMedia: true,
            stayAwake: true
        });
        // Join session
        await client.join(sessionName, signature, name, password);
        console.log('joined session');
    }, [meetingArgs, client, isSAB, sessionName, displayName, pronouns, password]);

    const enterMeetingRoom = async () => {
        // Stop video preview if applicable
        // Note: any microphone and speaker previews will automatically have ended
        //       when user clicked off the options panel to click 'Join'
        if (cameraPreviewStarted) {
            await previewCamera();
        }
        try {
            await initAndJoinSession();
            let meetingPath = '/video_conferencing/meeting';
            navigate(meetingPath, {
                state: {
                    pronouns: pronouns,
                    initialCameraId: previewCameraId,
                    initialMicId: previewMicId,
                    initialSpeakerId: previewSpeakerId,
                    startTime: startTime,
                    durationInMin: durationInMin,
                }
            });
        } catch (error) {
            openErrorModal(error);
            logError(error, { user: displayName });
        }
    }

    return (
        <>
            <WelcomePopup
                isOpen={isWelcomePopupOpen}
                setIsOpen={setWelcomePopupOpen}
                initialDisplayName={displayName}
                setDisplayName={setDisplayName}
                initialPronouns={pronouns}
                setUserPronouns={setPronouns}
                sessionName={sessionName}
            />
            <MeetingModal
                isOpen={isStartModalOpen}
                setIsOpen={setStartModalOpen}
                doAction={enterMeetingRoom}
                onClose={onModalClose}
                actionButtonText={'Join'}
                cancelButtonText={'Not Now'}
                messageStr={modalMessage}
                loadDuringAction={true}
                loadingText={'Joining Meeting...'}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                setIsOpen={setErrorModalOpen}
                message={errorMessage}
            />
            <div className='waiting-room'>
                <div className='header'>
                    <div>
                        <h1>{`${sessionName} - Waiting Room`}</h1>
                        <h2>
                            <span onClick={() => setWelcomePopupOpen(true)}>{(displayName && pronouns) && `Hi ${displayName} (${pronouns})!`}</span>
                            {' Please keep an eye on the meeting time.'}
                        </h2>
                    </div>
                    <img src='/krasman-transparent.png' className='company-logo' alt='logo' />
                </div>
                <div className='main-content'>
                    <div className={`video-feed-container ${sidebarOpen ? undefined : 'side-bar-closed'}`}>
                        <video
                            className={`video-feed`}
                            id='preview-camera-video'
                            playsInline
                            disablePictureInPicture
                            ref={videoRef}
                        />
                    </div>
                    <SidebarContext.Provider value={sidebarContextValue}>
                        <Sidebar />
                    </SidebarContext.Provider>
                </div>
                <div className='footer'>
                    <div className='io-buttons-container'>
                        <IOButton
                            type='video'
                            onClick={onCameraClick}
                            isOnParent={cameraPreviewStarted}
                            disableButton={false}
                            disableOnOffToggle={videoButtonDisabled}
                            disablePreviewAndSourceButtons={disablePreviewAndSourceButtons}
                            OnIcon={Icons.CameraOn}
                            OffIcon={Icons.CameraOff}
                            labelText='Camera'
                            IOOptionsPanel={CameraOptionsPanel}
                            panelProps={cameraPanelProps}
                        />
                        <IOButton
                            type='microphone'
                            onClick={() => { }}
                            disableButton={disableButtons}
                            disableOnOffToggle={false}
                            disablePreviewAndSourceButtons={disablePreviewAndSourceButtons}
                            OnIcon={Icons.MicrophoneOn}
                            OffIcon={Icons.MicrophoneOff}
                            labelText='Microphone'
                            IOOptionsPanel={MicrophoneOptionsPanel}
                            panelProps={microphonePanelProps}
                        />
                        <IOButton
                            type='speaker'
                            onClick={() => { }}
                            disableButton={disableButtons}
                            disableOnOffToggle={false}
                            disablePreviewAndSourceButtons={disablePreviewAndSourceButtons}
                            OnIcon={Icons.SpeakerOn}
                            OffIcon={Icons.SpeakerOff}
                            labelText='Audio'
                            IOOptionsPanel={AudioOptionsPanel}
                            panelProps={speakerPanelProps}
                        />
                    </div>
                    <div className='time-join-container'>
                        <label>{`Time ${isPastStart ? 'Past' : 'Until'} Meeting:`}</label>
                        <TimeDisplay isPastTarget={isPastStart} setIsPastTarget={setIsPastStart} targetDate={startDate} isWaitingRoom={true} />
                        <MeetingButton onClick={() => setStartModalOpen(true)} status={meetingButtonState} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default WaitingRoom;