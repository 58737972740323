import './VisualizeAudioBar.css';

const AudioBarSVG = ({ className, width, height, vbWidth, vbHeight }) => {
    return (
        // <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 21">
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} viewBox={`0 0 ${vbWidth} 21`}>
            <path d="M3 18V3M15.2143 18V3M27.4286 18V3M39.6429 18V3M51.8571 18V3M64.0714 18V3M76.2857 18V3M88.5 18V3M100.714 18V3M112.929 18V3M125.143 18V3M137.357 18V3M149.571 18V3M161.786 18V3M174 18V3" strokeWidth="5" strokeLinecap="round" />
        </svg>
    );
}

const MAX_FILLED = 177;

function calculatePartialWidth(value) {
    return MAX_FILLED * value / 100;
}

const VisualizeAudioBar = ({ value }) => {
    return (
        <div className='visualize-audio-bar'>
            <AudioBarSVG className='empty-bar' width={177} vbWidth={177} />
            <AudioBarSVG className='full-bar' width={calculatePartialWidth(value)} vbWidth={calculatePartialWidth(value)} />
        </div>
    )
}

export default VisualizeAudioBar;