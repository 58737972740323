import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PeerSupportForm from './pages/PeerSupportForm';
import FollowupForm from './pages/FollowupForm';
import Programs from './pages/Programs';
import Reports from './pages/Reports';
import Workers from './pages/Workers';
import ProgramRegistration from './pages/ProgramRegistration';
import SignIn from './components/SignIn';
import VirtualRoom from './pages/VirtualRoom';
import ProtectedRoute from './ProtectedRoute';
import Explore from './pages/Explore';
import VideoConferencingApp from './video_conferencing/VCApp';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/programs" element={<ProtectedRoute />}>
          <Route index element={<Programs />} />
        </Route>
        <Route path="/reports" element={<ProtectedRoute />}>
          <Route index element={<Reports />} />
        </Route>
        <Route path="/staff" element={<ProtectedRoute />}>
          <Route index element={<Workers />} />
        </Route>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/request-form" element={<PeerSupportForm />} />
        <Route path="/program-form/:programName" element={<ProgramRegistration />} />
        <Route path="/followupform" element={<FollowupForm />} />
        <Route path="/dashboard" element={<Home />} />
        {/* <Route path="/join" element={<VirtualRoom />} /> */}
        <Route path="/Explore" element={<Explore />} />
        <Route path="/video_conferencing/*" element={<VideoConferencingApp />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
