import { useState, useRef } from 'react';
import Modal from './common/Modal';
import ErrorMessage from './common/ErrorMessage';
import Separator from './common/Separator';

import './WelcomePopup.css';

const WelcomePopup = ({ isOpen, setIsOpen, initialDisplayName, setDisplayName, initialPronouns, setUserPronouns, sessionName }) => {
    const [pronouns, setPronouns] = useState(initialPronouns);
    const [name, setName] = useState(initialDisplayName);
    const [showCustomPronounsInput, setShowCustomPronounsInput] = useState(false);
    const [error, setError] = useState(false);
    const pronounsSelectRef = useRef(null);

    const updatePronouns = (value) => {
        if (value === 'custom') {
            setShowCustomPronounsInput(true);
            setPronouns('');
        } else {
            setShowCustomPronounsInput(false);
            setPronouns(value);
        }
    }

    const canClose = () => {
        if (pronouns === '' || name === '') {
            setError(true);
            return false;
        }
        setError(false);
        setUserPronouns(pronouns);
        setDisplayName(name);
        return true;
    }

    const close = () => {
        if (canClose()) {
            setIsOpen(false);
        }
    }

    return (
        <Modal className='welcome-popup-modal' isModalOpen={isOpen} setModalOpen={setIsOpen} canClose={canClose}>
            <div className='welcome-popup'>
                <div className='popup-header'>
                    <h2>Welcome to the Group Session!</h2>
                    <h1>{sessionName}</h1>
                </div>
                <Separator />
                <h3>Please let us know how you'd like to be addressed!</h3>
                <form className='name-pronouns-form'>
                    <div className='name-input-container'>
                        <label htmlFor='name-input'>Name</label>
                        <input type='text' id='name-input' value={name} onChange={(e) => setName(e.target.value)} placeholder='Please enter your name.' />
                    </div>
                    <div className='pronouns-input-container'>
                        <label htmlFor='pronouns-select'>Pronouns</label>
                        <select ref={pronounsSelectRef} id='pronouns-select' value={pronouns} onChange={(e) => updatePronouns(e.target.value.trim())}>
                            <option value=''>Select</option>
                            <option value='She/Her'>{'She/Her'}</option>
                            <option value='He/Him'>{'He/Him'}</option>
                            <option value='They/Them'>{'They/Them'}</option>
                            <option value='custom'>{'Other'}</option>
                        </select>
                        <input
                            className={showCustomPronounsInput ? 'show' : undefined}
                            type='text'
                            id='pronouns-input'
                            maxLength={11}
                            value={pronouns}
                            onChange={(e) => { setPronouns(e.target.value.trim()) }}
                            placeholder='Type here...' />
                    </div>
                </form>
                {error && <ErrorMessage message={'Please provide a name and pronouns before joining.'} />}
                <button onClick={close}>Join Waiting Room</button>
            </div>
        </Modal>
    );
}

export default WelcomePopup;