import { Checkmark } from '../../common/Icons';

import './SpotlightFilterToggle.css';

const SpotlightFilterToggle = ({ onClick, isActive, children }) => {
    return (

        <button
            onClick={onClick}
            className={`spotlight-filter-toggle ${isActive ? 'active' : 'inactive'}`}>
            <div className='inner-content'>
                {children}
                {isActive && <Checkmark />}
            </div>
        </button>
    );
}

export default SpotlightFilterToggle;