import Modal from './common/Modal';
import { CoffeeCup } from './common/Icons';

import './BreakModal.css';

const BreakModal = ({ isOpen, setIsOpen, onClick }) => {
    return (
        <Modal className='break-modal' isModalOpen={isOpen} setModalOpen={setIsOpen}>
            <div className='content'>
                <div className='icon-container'>
                    <CoffeeCup />
                </div>
                <div>
                    <h1>You are currently on break.</h1>
                    <p>
                        The meeting volume is decreased and your microphone and camera have been turned off.
                        When you feel ready to join the meeting again, click the button below.
                    </p>
                </div>
                <button onClick={onClick}>Return to the meeting</button>
            </div>
        </Modal>
    );
}

export default BreakModal;