import { useState, useCallback } from 'react';
import ZoomVideo from '@zoom/videosdk';

export function usePreview(videoRef) {
    const [cameraId, setCameraId] = useState(-1);
    const [cameraPreviewStarted, setVideoPreviewStarted] = useState(false);
    const [localVideo, setLocalVideo] = useState();

    const [micId, setMicId] = useState(-1);
    const [micPreviewStarted, setMicPreviewStarted] = useState(false);
    const [micTester, setMicTester] = useState();

    const [speakerId, setSpeakerId] = useState(-1);
    const [speakerPreviewStarted, setSpeakerPreviewStarted] = useState(false);
    const [speakerTester, setSpeakerTester] = useState();

    const [volume, setVolume] = useState(0);

    const fetchDevices = useCallback(async () => {
        let devices = await ZoomVideo.getDevices();
        let cameras = devices.filter(device => device.kind === 'videoinput');
        let mics = devices.filter(device => device.kind === 'audioinput');
        let speakers = devices.filter(device => device.kind === 'audiooutput');

        return {
            cameras,
            mics,
            speakers
        };
    });

    const previewCamera = useCallback(async () => {
        if (cameraPreviewStarted) {
            await localVideo?.stop();
            setVideoPreviewStarted(false);
        } else {
            if (cameraId && videoRef.current) {
                let videoTrack = ZoomVideo.createLocalVideoTrack(cameraId);
                await videoTrack.start(videoRef.current);
                setLocalVideo(videoTrack);
                setVideoPreviewStarted(true);
            }
        }
    }, [cameraPreviewStarted, cameraId, localVideo, videoRef]);

    const switchPreviewCamera = useCallback(async (deviceId) => {
        if (cameraPreviewStarted) {
            await previewCamera();
        }
        setCameraId(deviceId);
    }, [cameraPreviewStarted, previewCamera]);

    const previewMic = useCallback(() => {
        if (micPreviewStarted) {
            micTester?.destroy();
            setVolume(0);
            setMicTester(undefined);
            setMicPreviewStarted(false);
        } else {
            if (micId) {
                let audioTrack = ZoomVideo.createLocalAudioTrack(micId);
                let tester = audioTrack.testMicrophone({
                    onAnalyseFrequency: (v) => setVolume(v),
                    microphoneId: micId
                });
                setMicTester(tester);
                setMicPreviewStarted(true);
            }
        }
    }, [micPreviewStarted, micId, micTester]);

    const switchPreviewMic = useCallback((deviceId) => {
        if (micPreviewStarted) {
            previewMic();
        }
        setMicId(deviceId);
    }, [micPreviewStarted, previewMic]);

    const previewSpeaker = useCallback(() => {
        if (speakerPreviewStarted) {
            speakerTester?.destroy();
            setVolume(0);
            setSpeakerTester(undefined);
            setSpeakerPreviewStarted(false);
        } else {
            if (speakerId) {
                let audioTrack = ZoomVideo.createLocalAudioTrack(speakerId);
                let tester = audioTrack.testSpeaker({
                    onAnalyseFrequency: (v) => setVolume(v),
                    speakerId: speakerId
                });
                setSpeakerTester(tester);
                setSpeakerPreviewStarted(true);
            }
        }
    }, [speakerPreviewStarted, speakerId, speakerTester]);

    const switchPreviewSpeaker = useCallback((deviceId) => {
        if (speakerPreviewStarted) {
            previewSpeaker();
        }
        setSpeakerId(deviceId);
    }, [speakerPreviewStarted, previewSpeaker]);

    return {
        volume,
        previewCameraId: cameraId,
        previewMicId: micId,
        previewSpeakerId: speakerId,
        cameraPreviewStarted,
        micPreviewStarted,
        speakerPreviewStarted,
        fetchDevices,
        previewCamera,
        previewMic,
        previewSpeaker,
        switchPreviewCamera,
        switchPreviewMic,
        switchPreviewSpeaker
    };
}