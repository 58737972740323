import { useContext } from 'react';
import classNames from 'classnames';
import { ChatContext, SidebarContext } from '../../contexts/zoom-context';
import { Headers } from './Sidebar';
import * as Icons from '../common/Icons';

import './SidebarHeaderButton.css';

const AgendaButton = ({ showLabel, selectHeader, activeHeader }) => {
    return <SidebarHeaderButton
        showLabel={showLabel}
        isSelected={Headers.AGENDA === activeHeader}
        onClick={() => selectHeader(Headers.AGENDA)}
        Icon={Icons.Clipboard}
        text={'Agenda'}
    />
}

const ParticipantsButton = ({ showLabel, selectHeader, activeHeader }) => {
    return <SidebarHeaderButton
        showLabel={showLabel}
        isSelected={Headers.PARTICPANTS === activeHeader}
        onClick={() => selectHeader(Headers.PARTICPANTS)}
        Icon={Icons.Participant}
        text={'Participants'}
    />
}

const AbsenteesButton = ({ showLabel, selectHeader, activeHeader }) => {
    return <SidebarHeaderButton
        showLabel={showLabel}
        isSelected={Headers.ABSENTEES === activeHeader}
        onClick={() => selectHeader(Headers.ABSENTEES)}
        Icon={Icons.Absentee}
        text={'Absentees'}
    />
}

const BreakoutRoomsButton = ({ showLabel, selectHeader, activeHeader }) => {
    return <SidebarHeaderButton
        showLabel={showLabel}
        isSelected={Headers.BREAKOUT_ROOMS === activeHeader}
        onClick={() => selectHeader(Headers.BREAKOUT_ROOMS)}
        Icon={Icons.ParticpantsThree}
        text={'Breakout Rooms'}
    />
}

const ScreenShareButton = ({ showLabel, selectHeader, activeHeader }) => {
    const { isScreenSharing } = useContext(SidebarContext);
    return <SidebarHeaderButton
        className={isScreenSharing ? 'active-screen-share' : undefined}
        showLabel={showLabel}
        isSelected={Headers.SCREENSHARE === activeHeader}
        onClick={() => selectHeader(Headers.SCREENSHARE)}
        Icon={Icons.ScreenShare}
        text={'Screenshare'}
    />
}

const ChatButton = ({ showLabel, selectHeader, activeHeader }) => {
    const { totalUnread } = useContext(ChatContext);
    return (
        <div className='header-button-container'>
            {(!!totalUnread && !showLabel) && <div className='notif-dot'>{totalUnread}</div>}
            <SidebarHeaderButton
                showLabel={showLabel}
                isSelected={Headers.CHAT === activeHeader}
                onClick={() => selectHeader(Headers.CHAT)}
                Icon={Icons.Chat}
                text={'Chat'}
            />
        </div>
    );
}

const SpotlightButton = ({ showLabel, selectHeader, activeHeader }) => {
    return <SidebarHeaderButton
        showLabel={showLabel}
        isSelected={Headers.SPOTLIGHT === activeHeader}
        onClick={() => selectHeader(Headers.SPOTLIGHT)}
        Icon={Icons.Spotlight}
        text={'Spotlight'}
    />
}

const SidebarHeaderButton = ({ className, showLabel, isSelected, onClick, Icon, text }) => {
    const btnClass = classNames('header-button', {
        'small': showLabel,
        'large': !showLabel,
        'selected': isSelected,
        className: !!className
    });

    return (
        <button className={btnClass} onClick={onClick}>
            <div className='icon-container'>
                <Icon />
            </div>
            {!showLabel && <div className={`header-title`}>{text}</div>}
        </button>
    )
}

export { AgendaButton, ParticipantsButton, AbsenteesButton, BreakoutRoomsButton, ScreenShareButton, ChatButton, SpotlightButton };