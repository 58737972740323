export const devConfig = {
    sessionName: 'Development Session',
    password: '',
    role: 1,
    startTime: '16:00:00 UTC',
    durationInMin: 8 * 60
};

export const prodConfig = {
    sessionName: 'Team Meeting',
    password: '',
    role: 1,
    startTime: '17:00:00 UTC',
    durationInMin: 30
};