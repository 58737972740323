// Sidebar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css'; // Your custom CSS file

const Sidebar = () => {

    const location = useLocation();

    return (
        <div className="sidebar">
            <div className="sidebar-logo"> 
              <img src="peerlogoround.png" alt="Logo" className="peerlogo" />
            </div>
            <Link to="/" className={location.pathname === "/" ? "selected" : ""}>Requests</Link>
            <Link to="/staff" className={location.pathname === "/staff" ? "selected" : ""}>Staff</Link>
            <Link to="/programs" className={location.pathname === "/programs" ? "selected" : ""}>Programs</Link>
            <Link to="/reports" className={location.pathname === "/reports" ? "selected" : ""}>Reports</Link>
            <Link to="/video_conferencing">Video Conferencing</Link>
        </div>
    );
};

export default Sidebar;