import React, { useEffect, useState } from 'react';
import ContentRectangle from './ContentRectangle'; 
import { database } from '../firebase'; // Import the database from firebase.js
import { ref, onValue } from 'firebase/database';

const DynamicContent = ({ selectedLink }) => {
  
  const [data, setData] = useState({
    backlog: [],
    ongoing: [],
    completed: [],
  });

  useEffect(() => {
    if (selectedLink) {
      if (selectedLink === "ongoing") {
        const fetchFromDB = (link) => {
          const dbRef = ref(database, link);
          return new Promise((resolve) => {
            onValue(dbRef, (snapshot) => {
              const fetchedData = snapshot.val();
              const transformedData = fetchedData ? Object.entries(fetchedData).map(([id, value]) => ({ ...value, id })) : [];
              resolve(transformedData);
            }, {
              onlyOnce: true,
            });
          });
        };

        Promise.all([fetchFromDB("ongoing"), fetchFromDB("followup")]).then(([ongoingData, followupData]) => {
          setData(prevData => ({ ...prevData, ongoing: [...ongoingData, ...followupData] }));
        });

      } else {
        const dbRef = ref(database, selectedLink);
        onValue(dbRef, (snapshot) => {
          const fetchedData = snapshot.val();
          const transformedData = fetchedData ? Object.entries(fetchedData).map(([id, value]) => ({ ...value, id })) : [];
          setData(prevData => ({ ...prevData, [selectedLink]: transformedData }));
        }, {
          onlyOnce: true,
        });
      }
    }
  }, [selectedLink]);

  const handleRemoveItem = (itemId) => {
    setData(prevData => ({
      ...prevData,
      [selectedLink]: prevData[selectedLink].filter(item => item.id !== itemId)
    }));
  }

  const handleWorkerChange = (itemId, newWorker) => {
    setData(prevData => ({
      ...prevData,
      [selectedLink]: prevData[selectedLink].map(item => item.id === itemId ? {...item, psworker: newWorker} : item)
    }));
  }

  const FallbackContent = () => {
    if (selectedLink === "backlog") {
      return (
        <>
          <p className="fallback-text">Nothing here</p>
        </>
      );
    } else if (selectedLink === "ongoing") {
      return (
        <>
          <p className="fallback-text">Nothing here</p>
        </>
      );
    }
    return null;
  };

  return (
    <div className="dynamic-content">
      <div className="content">
        {selectedLink && data[selectedLink].length ? (
          data[selectedLink].map((item, index) => (
            <ContentRectangle
              key={index}
              name={`${item.FirstName} ${item.LastName}`}
              description={item.SupportRequest}
              psworker={item.psworker}
              status={item.status}
              linkType={selectedLink}
              itemId={item.id}
              onRemove={handleRemoveItem}
              onWorkerChange={handleWorkerChange}
            />
          ))
        ) : (
          <FallbackContent />
        )}
      </div>
    </div>
  );
};

export default DynamicContent;
