import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAj80iiBEw9aHIiGjPUjY-TFB_txflE0Vw",
    authDomain: "peersupportiodemo.firebaseapp.com",
    databaseURL: "https://peersupportiodemo-default-rtdb.firebaseio.com",
    projectId: "peersupportiodemo",
    storageBucket: "peersupportiodemo.appspot.com",
    messagingSenderId: "1006339201790",
    appId: "1:1006339201790:web:24821c1c0034c8d94663af",
    measurementId: "G-WY3Y8LR1HG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Firebase Realtime Database
const database = getDatabase(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

export { app, database, functions, httpsCallable };
